import React from "react";
import Container from "@material-ui/core/Container";
import NavBar from "../../Shared/Navigation/Navbar";
import StaffViewContainer from "../components/StaffViewContainer";
export default function StaffView() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: "64px" }}>
        <StaffViewContainer />
      </Container>
    </>
  );
}

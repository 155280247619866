import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

export default function SavedConfirmation({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogContent>
          <Typography variant="h3" textAlign="center">Hours Saved!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

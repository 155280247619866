import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { PTOTypesAtom, selectedSchoolYearAtom, rolloverObjAtom } from "../../recoil/Atoms";
import { Box, Grid, Typography } from "@mui/material";
import SummaryCard from "./SummaryCard";

export default function PTOSummaryContainer({ user }) {
  const [availableTypes, setAvailableTypes] = useState();
  const PTOTypes = useRecoilValue(PTOTypesAtom);
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const rolloverObj = useRecoilValue(rolloverObjAtom);

  useEffect(() => {
    if (!PTOTypes || !user || !selectedSchoolYear) return;
    const filteredResults = PTOTypes.filter(
      (type) => type.policy === user.ptoPolicy && type.schoolYear === selectedSchoolYear
    );
    setAvailableTypes(filteredResults);
  }, [PTOTypes, user, selectedSchoolYear]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography sx={{ pt: 2, marginBottom: "10px" }} variant="h3">
        PTO Summary
      </Typography>
      <Grid container spacing={2}>
        {availableTypes &&
          availableTypes.map((PTOType) => (
            <SummaryCard
              PTOType={PTOType}
              key={PTOType.id}
              user={user}
              rolloverObj={rolloverObj}
              request={null}
            />
          ))}
      </Grid>
    </Box>
  );
}

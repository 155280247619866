import { atom, selector } from "recoil";
import {
  selectAllRequestsGetter,
  selectUserRequestsGetter,
  importantDatesGetter,
  payPeriodsGetter,
} from "./Selectors";

export const schoolYearsAtom = atom({
  key: "schoolYears",
  default: null,
});

export const schoolYearsObjAtom = atom({
  key: "schoolYearsObj",
  default: null,
});

export const schoolYearsResetAtom = atom({
  key: "schoolYearsReset",
  default: true,
});

export const PTOTypesAtom = atom({
  key: "PTOTypes",
  default: null,
});

export const selectPTOTypesAtom = selector({
  key: "selectPTOTypes",
  get: ({ get }) => {
    const PTOTypes = get(PTOTypesAtom);
    return PTOTypes;
  },
});

export const PTOTypesObjAtom = atom({
  key: "PTOTypesObj",
  default: null,
});

export const PTOTypesResetAtom = atom({
  key: "PTOTypesReset",
  default: true,
});

export const selectUserRequestsAtom = selector({
  key: "userRequests",
  get: selectUserRequestsGetter,
});

export const userRequestsResetAtom = atom({
  key: "userRequestsReset",
  default: true,
});

export const allRequestsAtom = atom({
  key: "allRequests",
  default: null,
});

export const selectAllRequestsAtom = selector({
  key: "selectAllRequests",
  get: selectAllRequestsGetter,
});

export const allRequestsResetAtom = atom({
  key: "allRequestsReset",
  default: true,
});

export const staffObjAtom = atom({
  key: "staffObj",
  default: null,
});

export const currentUserAtom = atom({
  key: "currentUser",
  default: null,
});

export const PTOPoliciesAtom = atom({
  key: "PTOPolicies",
  default: [
    "10-Month-Non-Exempt",
    "10-Month-Exempt",
    "12-Month-Non-Exempt",
    ,
    "12-Month-Exempt",
    "10-Month",
    "12-Month",
    "12-Month-Leadership",
    "No Policy",
  ],
});

export const statusesAtom = atom({
  key: "statuses",
  default: {
    pending: "pending",
    approved: "approved",
    denied: "denied",
    unpaid: "unpaid",
    cancelled: "cancelled",
  },
});

export const allStaffAtom = atom({
  key: "allStaff",
  default: null,
});

export const selectedSchoolYearAtom = atom({
  key: "selectedSchoolYear",
  default: null,
});

export const importantDatesAtom = atom({
  key: "importantDates",
  default: null,
});

export const importantDatesResetAtom = atom({
  key: "importantDatesReset",
  default: false,
});

export const selectImportantDatesAtom = selector({
  key: "selectImportantDates",
  get: importantDatesGetter,
});

export const rolloverObjAtom = atom({
  key: "rolloverObj",
  default: null,
});

export const rolloverResetAtom = atom({
  key: "rolloverReset",
  default: false,
});

export const currentUserHoursAtom = atom({
  key: "currentUserHours",
  default: null,
});

export const currentUserHoursResetAtom = atom({
  key: "currentUserHoursReset",
  default: false,
});

export const allHoursAtom = atom({
  key: "allHours",
  default: null,
});

export const allHoursResetAtom = atom({
  key: "allHoursReset",
  default: null,
});

export const setPayPeriodsAtom = selector({
  key: "setPayPeriods",
  get: payPeriodsGetter,
});

export const allHoursApprovalsAtom = atom({
  key: "allHoursApprovals",
  default: null,
});

export const allHoursApprovalsResetAtom = atom({
  key: "allHoursApprovalsReset",
  default: false,
});

export const allHoursLogsAtom = atom({
  key: "allHoursLogs",
  default: [],
});

export const hoursLogsResetAtom = atom({
  key: "hoursLogsReset",
  default: false,
});

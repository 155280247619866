import * as React from "react";
import { useRecoilValue } from "recoil";
import { selectUserRequestsAtom } from "../../recoil/Atoms";
import { Box, Typography, FormControlLabel, Switch } from "@mui/material";
import PTORequest from "./PTORequest";
import useWindowDimensions from "../../Shared/useWindowDimensions";
import CancelConfirmation from "./CancelConfirmation";

export default function PTORequests({
  handleCancel,
  cancelOpen,
  setCancelOpen,
  cancelRequestId,
  setCancelRequestId,
}) {
  const userRequests = useRecoilValue(selectUserRequestsAtom);
  const { height } = useWindowDimensions();

  return (
    <Box sx={{ mt: 2 }}>
      <Typography sx={{ pt: 2, marginBottom: "10px" }} variant="h3">
        My PTO Requests
      </Typography>
      <Box
        sx={{
          height: height * 0.48,
          overflow: "scroll",
          overflowY: "visilbe",
          borderRadius: "5px",
          boxShadow: "0 0 5px #bbb"
        }}
      >
        {userRequests &&
          userRequests.map((request) => (
            <PTORequest
              request={request}
              key={request.id}
              setCancelOpen={setCancelOpen}
              cancelRequestId={cancelRequestId}
              setCancelRequestId={setCancelRequestId}
            />
          ))}
      </Box>
      <CancelConfirmation
        handleCancel={handleCancel}
        open={cancelOpen}
        setOpen={setCancelOpen}
        setCancelRequestId={setCancelRequestId}
        cancelRequestId={cancelRequestId}
      />
    </Box>
  );
}

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";

export default function AdminNotes({ adminNotes, setAdminNotes }) {
  const handleChange = (e) => setAdminNotes(e.target.value);
  return (
    <Box sx={{ minWidth: 120, mt: 2 }}>
      <Typography variant="h3">Admin Decision</Typography>
      <TextField
        fullWidth
        style={{ marginBottom: "1em", marginTop: "1em" }}
        id="adminNotes"
        name="adminNotes"
        value={adminNotes}
        multiline
        rows={4}
        onChange={handleChange}
      />
    </Box>
  );
}

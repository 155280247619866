import { useState, useCallback } from "react";
import { DateTime } from "luxon";
import { selectedSchoolYearAtom, schoolYearsObjAtom } from "../recoil/Atoms";
import { useRecoilValue } from "recoil";

const useGetPayPeriods = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const schoolYearsObj = useRecoilValue(schoolYearsObjAtom);

  const getDayLabels = (dtStartWeek) =>
    [0, 1, 2, 3, 4].map((dayOffset) => {
      const [month, day, year] = dtStartWeek.plus({ days: dayOffset }).toFormat("D").split("/");
      return `${month}/${day}/${year}`;
    });
  const YEAR_TOTAL_WEEKS = 52;
  const YEAR_TOTAL_CYCLES = 26;
  const YEAR_FIRST_JULY_WEEK = 27;

  const getPayPeriods = useCallback(({ firstWeek, selectedSchoolYearObj }) => {
    setIsLoading(true);
    setError(null);

    try {
      const firstWeekNumber = firstWeek.weekNumber;
      if (firstWeekNumber !== YEAR_FIRST_JULY_WEEK) {
        console.warn(
          "[Component] HoursContainer - First Week Number was not 27, check first pay date in calendar year settings"
        );
      }
      const firstYearNumber = firstWeek.year;

      let weekNumber = firstWeekNumber;
      const tempArray = [];
      let dtCurrentWeekStart = DateTime.fromFormat(selectedSchoolYearObj.firstPayWeek, "D").set({
        year: firstYearNumber,
      });
      console.log(dtCurrentWeekStart.day);
      for (let i = 0; i < YEAR_TOTAL_CYCLES; i++) {
        const dtSecondWeekStart = dtCurrentWeekStart.plus({ week: 1 });
        const dtWeekTwoForLabel = dtSecondWeekStart.plus({ days: 4 });
        const datesArray = [];
        datesArray.push(...getDayLabels(dtCurrentWeekStart));
        datesArray.push(...getDayLabels(dtSecondWeekStart));

        const [month, day, year] = dtCurrentWeekStart.toFormat("D").split("/");
        const [secondMonth, secondDay, secondYear] = dtWeekTwoForLabel.toFormat("D").split("/");

        tempArray.push({
          label: `${month}/${day}/${year} - ${secondMonth}/${secondDay}/${secondYear}`,
          weekOneNumber: dtCurrentWeekStart.weekNumber,
          weekTwoNumber: (dtCurrentWeekStart.weekNumber + 1) % YEAR_TOTAL_WEEKS,
          datesArray: datesArray,
          year: year,
        });

        dtCurrentWeekStart = dtCurrentWeekStart.plus({ week: 2 });
      }
      return tempArray;
    } catch (err) {
      setError(err.message || "Something went wrong!");
    }
    setIsLoading(false);
  }, []);
  return { isLoading, error, getPayPeriods };
};

export default useGetPayPeriods;

import React from "react";
import { Box, Divider } from "@mui/material";
import parse from "html-react-parser";

function HoursLog({ message }) {
  return (
    <>
      <Box sx={{ padding: 2, backgroundColor: "#fafafa", margin: 2, borderRadius: 2 }}>
        {parse(message)}
      </Box>
      <Divider />
    </>
  );
}

export default HoursLog;

import React from "react";
import Container from "@material-ui/core/Container";
import NavBar from "../../Shared/Navigation/Navbar";
import CalendarContainer from "../components/CalendarContainer";

export default function Calendar() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: "64px" }}>
        <CalendarContainer />
      </Container>
    </>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { staffObjAtom, currentUserAtom, allStaffAtom } from "../recoil/Atoms";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [authUser, setAuthUser] = useState(false);
  const [staff, setStaff] = useState();
  const setStaffObj = useSetRecoilState(staffObjAtom);
  const setNewCurrentUser = useSetRecoilState(currentUserAtom);
  const setAllStaff = useSetRecoilState(allStaffAtom);

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider).then(async (result) => {
      setLoginLoading(true);
      result.user.email.split("@")[1] === "thegatheringplacek12.org" || auth.signOut();
    });
  };

  function logout() {
    setCurrentUser(null);
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setAuthUser(true);
        validateUser(user);
      } else {
        setAuthUser(false);
      }
      setLoading(false);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser) {
      setLoginLoading(false);
    }
  }, [currentUser]);

  const validateUser = async (user) => {
    let staff = [];
    try {
      const response = await fetch(
        "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/staff",
        {
          headers: {
            authorization: process.env.REACT_APP_FIREBASE_API_KEY,
          },
        }
      );
      staff = await response.json();
    } catch (error) {
      console.error("[API ERROR] API wouldn't load, the error was: ", error);
    }
    const tempAllStaff = [];
    const tempStaffObj = {};
    const tempActiveStaff = [];
    staff.forEach((s) => {
      tempAllStaff.push(s);
      tempStaffObj[s.id] = s;
      if (s.active && !s.terminated) {
        tempActiveStaff.push(s);
      }
      if (s.id === user.email) {
        setCurrentUser(s);
        setNewCurrentUser(s);
      }
    });
    setStaff(tempActiveStaff);
    setAllStaff(tempActiveStaff);
    setStaffObj(tempStaffObj);
  };

  const value = {
    currentUser,
    loginLoading,
    signInWithGoogle,
    logout,
    auth,
    authUser,
    loading,
    staff,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}

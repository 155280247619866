import React from "react";
import { Card, CardContent, Button, Typography, Box, Divider, Grid, Chip } from "@mui/material";
import { useRecoilValue } from "recoil";
import { PTOTypesObjAtom, statusesAtom } from "../../recoil/Atoms";

export default function PTORequest({ request, setCancelOpen, setCancelRequestId }) {
  const PTOTypesObj = useRecoilValue(PTOTypesObjAtom);
  const statuses = useRecoilValue(statusesAtom);
  const [start, end] = request.dateRange;
  const cancelConirm = () => {
    setCancelOpen(true);
    setCancelRequestId(request.id);
  };

  return (
    <>
      {PTOTypesObj && (
        <Card sx={{ minWidth: 275, margin: 2, backgroundColor: (request.status === statuses.cancelled ? "#ddd" : "#fff")}}>
          <CardContent>
            <Grid container>
              <Grid item sm={4}>
                <Typography sx={{ mt: 2 }}>
                  <b>Type: </b>
                  {request.PTOTypeName}
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography sx={{ mt: 2 }}>
                  <b>Dates: </b>
                  {`${start} to ${end}`}
                </Typography>
              </Grid>
              <Grid item sm={2}>
                <Typography sx={{ mt: 2 }}>
                  <b>Hours: </b>
                  {request.hours}
                </Typography>
              </Grid>
              <Grid item sm={2}>
                {request.status !== statuses.cancelled && (
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      sx={{ mt: 2, ml: 2 }}
                      size="small"
                      color="secondary"
                      variant="contained"
                      onClick={cancelConirm}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Grid container>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 1 }}>
                  <Typography>
                    <b>Status:</b>
                  </Typography>
                  <Chip color={request.status} label={request.status} />
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Typography>
                  <b>PTO Reason:</b> {request.reason}
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography>
                  <b>Admin Notes:</b> {request.adminNotes}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}

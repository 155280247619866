import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  PTOTypesObjAtom,
  staffObjAtom,
  rolloverObjAtom,
  allRequestsResetAtom,
} from "../../recoil/Atoms";
import { Button, Dialog, Typography, DialogActions, DialogContent } from "@mui/material";
import useDeactivateDoc from "../../hooks/useDeactivateDoc";
import RequestDetails from "./RequestDetails";
import AdminNotes from "./AdminNotes";
import AdminHistory from "./AdminHistory";
import ApproveDenyButtons from "./ApproveDenyButtons";
import SummaryCard from "../../PTOSummary/components/SummaryCard";
import DeleteRequestConfirmation from "./DeleteRequestConfirmation";

export default function RequestDialog({
  selectedRow,
  open,
  setOpen,
  adminNotes,
  setAdminNotes,
  handleStatusChange,
}) {
  const PTOTypesObj = useRecoilValue(PTOTypesObjAtom);
  const staffObj = useRecoilValue(staffObjAtom);
  const rolloverObj = useRecoilValue(rolloverObjAtom);
  const setAllRequestsReset = useSetRecoilState(allRequestsResetAtom);
  const { sendRequest: deactivateDoc } = useDeactivateDoc();
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleDelete = () => {
    deactivateDoc({ col: "PTORequests", id: selectedRow.id });
    setAllRequestsReset((pV) => !pV);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = () => {
    setDeleteOpen(true);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
        <Typography variant="h2" sx={{ padding: 2 }}>
          Request Details
        </Typography>
        <DialogContent>
          <RequestDetails selectedRow={selectedRow} />
          <AdminNotes adminNotes={adminNotes} setAdminNotes={setAdminNotes} />
          {selectedRow && selectedRow.status !== "cancelled" && (
            <ApproveDenyButtons selectedRow={selectedRow} handleStatusChange={handleStatusChange} />
          )}
          {selectedRow && selectedRow.status === "cancelled" && (
            <Typography>This was cancelled by the employee.</Typography>
          )}
          {selectedRow && selectedRow.history && <AdminHistory />}
          {rolloverObj && PTOTypesObj && staffObj && (
            <SummaryCard
              PTOType={PTOTypesObj[selectedRow.PTOType]}
              user={staffObj[selectedRow.requester]}
              rolloverObj={rolloverObj}
              request={selectedRow}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClick}>Delete Request</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <DeleteRequestConfirmation
        open={deleteOpen}
        setOpen={setDeleteOpen}
        handleClick={handleDelete}
      />
    </>
  );
}

import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@material-ui/core/Typography";
import PTOTypeForm from "./PTOTypeForm";
import DialogContent from "@mui/material/DialogContent";

export default function PTOTypeDialog({ open, setOpen, PTOForm, setPTOForm, handleSubmit }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <Typography variant="h2" sx={{ padding: 2 }}>
        {PTOTypeForm.id ? "Edit PTO Type" : "New PTO Type"}
      </Typography>
      <DialogContent>
        <PTOTypeForm PTOForm={PTOForm} setPTOForm={setPTOForm} handleSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
}

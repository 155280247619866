import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

export default function DeleteRequestConfirmation({ open, setOpen, handleClick }) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    handleClick();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>"Delete PTO Request?"</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this PTO Request? This action can not be undo.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Nevermind</Button>
        <Button onClick={handleDelete} autoFocus>
          Yes, Delete PTO Request
        </Button>
      </DialogActions>
    </Dialog>
  );
}

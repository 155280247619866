import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { statusesAtom } from "../../recoil/Atoms";
import { Box, Typography, Divider, Fab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default function RequestDetails({ selectedRow }) {
  const statuses = useRecoilValue(statusesAtom);
  const [FAB, setFAB] = useState();
  const [editForm, setEditForm] = useState();
  const [editOpen, setEditOpen] = useState(false);

  const iconSize = {
    width: 25,
    lineHeight: 25,
    height: 25,
    minHeight: 25,
    marginLeft: 3,
  };

  const handleClick = (field, value) => {
    setEditForm({
      field: field,
      value: value,
    });
    setEditOpen(true);
  };

  return (
    <>
      {selectedRow && (
        <Box>
          <Typography sx={{ mt: 2 }}>
            <b>Request From:</b> {selectedRow.requesterName}
          </Typography>

          <Typography
            onMouseEnter={() => {
              setFAB("");
            }}
            onMouseLeave={() => {
              setFAB("");
            }}
            sx={{ mt: 2 }}
          >
            <b>Request Type:</b> {selectedRow.PTOTypeName}
            {FAB && FAB === "type" && (
              <Fab color="secondary" aria-label="edit" size="small" sx={iconSize}>
                <EditIcon
                  sx={{ fontSize: 18 }}
                  onClick={() => {
                    handleClick("type", selectedRow.typeID);
                  }}
                />
              </Fab>
            )}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <b>Request Reason:</b> {selectedRow.reason}
          </Typography>
          <Typography
            onMouseEnter={() => {
              setFAB("");
            }}
            onMouseLeave={() => {
              setFAB("");
            }}
            sx={{ mt: 2 }}
          >
            <b>Request Start Date:</b> {selectedRow.startDate}
            {FAB && FAB === "startDate" && (
              <Fab color="secondary" aria-label="edit" size="small" sx={iconSize}>
                <EditIcon
                  sx={{ fontSize: 18 }}
                  onClick={() => {
                    handleClick("startDate", [selectedRow.startDate, selectedRow.endDate]);
                  }}
                />
              </Fab>
            )}
          </Typography>
          <Typography
            onMouseEnter={() => {
              setFAB("");
            }}
            onMouseLeave={() => {
              setFAB("");
            }}
            sx={{ mt: 2 }}
          >
            <b>Request End Date:</b> {selectedRow.endDate}
            {FAB && FAB === "endDate" && (
              <Fab color="secondary" aria-label="edit" size="small" sx={iconSize}>
                <EditIcon
                  sx={{ fontSize: 18 }}
                  onClick={() => {
                    handleClick("endDate", [selectedRow.startDate, selectedRow.endDate]);
                  }}
                />
              </Fab>
            )}
          </Typography>
          <Typography
            onMouseEnter={() => {
              setFAB("");
            }}
            onMouseLeave={() => {
              setFAB("");
            }}
            sx={{ mt: 2 }}
          >
            <b>Total Requested Hours:</b> {selectedRow.hours}
            {FAB && FAB === "hours" && (
              <Fab color="secondary" aria-label="edit" size="small" sx={iconSize}>
                <EditIcon
                  sx={{ fontSize: 18 }}
                  onClick={() => {
                    handleClick("hours", selectedRow.hours);
                  }}
                />
              </Fab>
            )}
          </Typography>
          {selectedRow.additionalInfo && (
            <Typography sx={{ mt: 2 }}>
              <b>Notes about Partial Day: </b> {selectedRow.additionalInfo}
            </Typography>
          )}
          <Typography sx={{ mt: 2 }}>
            <b>Request Submitted At:</b> {selectedRow.submittedAt}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <b>Current Status:</b> {selectedRow.status}
          </Typography>
          {selectedRow.status !== statuses.pending && (
            <>
              <Typography sx={{ mt: 2 }}>
                <b>Approver:</b> {selectedRow.approver}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <b>Decision Timestsamp:</b> {selectedRow.decisionMadeAt}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <b>Decision Notes:</b> {selectedRow.adminNotes}
              </Typography>
            </>
          )}
          <Divider />
        </Box>
      )}
    </>
  );
}

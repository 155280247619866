import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useRecoilValue } from "recoil";
import { schoolYearsAtom } from "../../recoil/Atoms";

export default function PTOSchoolYear({ PTOForm, handleChange }) {
  const schoolYears = useRecoilValue(schoolYearsAtom);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select required value={PTOForm.schoolYear} name="schoolYear" onChange={handleChange}>
          {schoolYears.map((year) => (
            <MenuItem key={year.id} value={year.id}>
              {year.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

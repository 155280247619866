import React, { useState, useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  allRequestsResetAtom,
  currentUserAtom,
  selectedSchoolYearAtom,
  importantDatesAtom,
} from "../../recoil/Atoms";
import useAddDoc from "../../hooks/useAddDoc";
import SubmitRequestDialog from "./SubmitRequestDialog";
import { getValue, checkImportantDates } from "../libraries/functions";
import { DateTime } from "luxon";
import { blankForm } from "../libraries/objects";

export default function RequestContainer({ open, setOpen, source }) {
  const { sendRequest: addDoc } = useAddDoc();
  const [newRequestReset, setNewRequestReset] = useState(false);
  const [form, setForm] = useState(blankForm);
  const [selectedStaffPTO, setSelectedStaffPTO] = useState();
  const currentUser = useRecoilValue(currentUserAtom);
  const allRequestsReset = useSetRecoilState(allRequestsResetAtom);
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const importantDates = useRecoilValue(importantDatesAtom);
  const [conflicts, setConflicts] = useState();

  useEffect(() => {
    if (!currentUser) return;
    setSelectedStaffPTO(currentUser.ptoPolicy);
    setForm((pV) => ({ ...pV, requester: currentUser.id }));
  }, [currentUser, open]);

  useEffect(() => {
    if (!selectedSchoolYear) return;
    setForm((pV) => ({ ...pV, schoolYear: selectedSchoolYear }));
  }, [selectedSchoolYear, open]);

  const renderDateRange = ([start, end]) => {
    if (!start || !end) return;
    const dateRange = [start.toFormat("D"), end.toFormat("D")];
    const alert = checkImportantDates({ importantDates, dateRange });
    setConflicts(alert);
    let hours = 0;
    const { days: daysDiff } = end.diff(start, ["days"]).toObject();
    for (let i = 0; i < daysDiff + 1; i++) {
      if (start.plus({ days: i }).weekday < 6) {
        hours += 8;
      }
    }
    setForm((pV) => {
      return {
        ...pV,
        fullDay: true,
        dateRange: dateRange,
        hours: hours,
      };
    });
  };

  const handleChange = (e) => {
    const value = getValue(e);
    setForm((pV) => {
      return { ...pV, [e.target.name]: value };
    });
  };

  const handleSubmit = async (e, source) => {
    e.preventDefault();
    const data = { ...form };
    await addDoc({ col: "PTORequests", data: data });
    setNewRequestReset(!newRequestReset);
    allRequestsReset((pV) => !pV);
    setOpen(false);
    setForm({ ...blankForm, requester: currentUser.id, schoolYear: selectedSchoolYear });
  };
  return (
    <SubmitRequestDialog
      open={open}
      setOpen={setOpen}
      form={form}
      setForm={setForm}
      handleSubmit={handleSubmit}
      renderDateRange={renderDateRange}
      handleChange={handleChange}
      source={source}
      setSelectedStaffPTO={setSelectedStaffPTO}
      selectedStaffPTO={selectedStaffPTO}
      conflicts={conflicts}
      setConflicts={setConflicts}
    />
  );
}

export const loopArray = ["M: ", "Tu: ", "W: ", "Th: ", "F: "];

export const calcDisabled = ({ importantDatesToShow, payPeriod, dayOffset, hoursApproved }) => {
  if (importantDatesToShow[payPeriod.datesArray[dayOffset]] && !hoursApproved) {
    return importantDatesToShow[payPeriod.datesArray[dayOffset]].holiday;
  } else {
    return hoursApproved;
  }
};

export const calcValue = ({ currentPeriodForm, payPeriod, dayOffset, focused }) => {
  if (currentPeriodForm[payPeriod.datesArray[dayOffset]]) {
    return String(currentPeriodForm[payPeriod.datesArray[dayOffset]]);
  } else {
    return String(focused ? " " : 0);
  }
};

export const createLabel = ({ day, payPeriod, dayOffset, importantDatesToShow, userRequests }) => {
  const ptoObj = {};
  if (userRequests) {
    userRequests.forEach((request) => {
      const [startDay, endDay] = request.dateRange;
      let ptoStarted = false;
      payPeriod.datesArray.forEach((date) => {
        if (startDay === date) {
          ptoStarted = true;
        }
        if (ptoStarted) {
          ptoObj[date] = request.fullDay ? "Full Day PTO" : "Partial Day PTO";
          if (endDay === date) {
            ptoStarted = false;
          }
        }
      });
    });
  }

  return `${day} ${payPeriod.datesArray[dayOffset]} ${
    importantDatesToShow[payPeriod.datesArray[dayOffset]]
      ? importantDatesToShow[payPeriod.datesArray[dayOffset]].name
      : ""
  } ${ptoObj[payPeriod.datesArray[dayOffset]] ? ptoObj[payPeriod.datesArray[dayOffset]] : ""}`;
};

import { DateTime } from "luxon";

export const calculateStartMonth = (dt) => {
  if (dt.day === 1) {
    return dt.month > 6 ? dt.month - 6 : dt.month + 6;
  } else {
    if (dt.month === 12) {
      return 1;
    } else if (dt.month > 6) {
      return dt.month + 1 - 6;
    } else {
      return dt.month + 1 + 6;
    }
  }
};

export const calculateSchoolMonth = (dt) => {
  if (dt.month > 6) {
    return dt.month - 6;
  } else {
    return dt.month + 6;
  }
};

export const getAccrualStart = (ptoPolicy, schoolYear) => {
  console.log(schoolYear);
  let dtStart;
  switch (ptoPolicy) {
    case "10-Month":
      dtStart = DateTime.fromFormat(schoolYear.tenMonthStartDate, "D");
      return calculateStartMonth(dtStart);
    case "10-Non-Exempt":
      dtStart = DateTime.fromFormat(schoolYear.tenMonthStartDate, "D");
      return calculateStartMonth(dtStart);
    case "10-Month-Exempt":
      dtStart = DateTime.fromFormat(schoolYear.tenMonthStartDate, "D");
      return calculateStartMonth(dtStart);
    case "12-Month":
      dtStart = DateTime.fromFormat(schoolYear.twelveMonthStartDate, "D");
      return calculateStartMonth(dtStart);
    case "12-Month-Non-Exempt":
      dtStart = DateTime.fromFormat(schoolYear.twelveMonthStartDate, "D");
      return calculateStartMonth(dtStart);
    case "12-Month-Exempt":
      dtStart = DateTime.fromFormat(schoolYear.twelveMonthStartDate, "D");
      return calculateStartMonth(dtStart);
    case "12-Month":
      dtStart = DateTime.fromFormat(schoolYear.twelveMonthStartDate, "D");
      return calculateStartMonth(dtStart);
    case "12-Month-Leadership":
      dtStart = DateTime.fromFormat(schoolYear.twelveMonthStartDate, "D");
      console.log(schoolYear);
      return calculateStartMonth(dtStart);
    default:
      return 8;
  }
};

export const calculateEndMonth = (ptoPolicy, schoolYear) => {
  let endMonth;
  switch (ptoPolicy) {
    case "10-Month":
      endMonth = DateTime.fromFormat(schoolYear.tenMonthEndDate, "D");
      return calculateSchoolMonth(endMonth);
      case "10-Month-Non-Exempt":
      endMonth = DateTime.fromFormat(schoolYear.tenMonthEndDate, "D");
      return calculateSchoolMonth(endMonth);
      case "10-Month-Exempt":
      endMonth = DateTime.fromFormat(schoolYear.tenMonthEndDate, "D");
      return calculateSchoolMonth(endMonth);
    case "12-Month":
      endMonth = DateTime.fromFormat(schoolYear.twelveMonthEndDate, "D");
      return calculateSchoolMonth(endMonth);
      case "12-Month-Non-Exempt":
      endMonth = DateTime.fromFormat(schoolYear.twelveMonthEndDate, "D");
      return calculateSchoolMonth(endMonth);
      case "12-Month-Exempt":
      endMonth = DateTime.fromFormat(schoolYear.twelveMonthEndDate, "D");
      return calculateSchoolMonth(endMonth);
    case "12-Month-Leadership":
      endMonth = DateTime.fromFormat(schoolYear.twelveMonthEndDate, "D");
      return calculateSchoolMonth(endMonth);
    default:
      return 8;
  }
};

export const calculateAmountPerMonth = (ptoPolicy) => {
  switch (ptoPolicy.policy) {
    case "10-Month":
      return ptoPolicy.totalHours / 10;
      case "10-Month-Exempt":
      return ptoPolicy.totalHours / 10;
      case "10-Month-Non-Exempt":
      return ptoPolicy.totalHours / 10;
    case "12-Month":
      return ptoPolicy.totalHours / 12;
      case "12-Month-Exempt":
      return ptoPolicy.totalHours / 12;
      case "12-Month-Non-Exempt":
      return ptoPolicy.totalHours / 12;
    case "12-Month-Leadership":
      return ptoPolicy.totalHours / 12;
    default:
      return ptoPolicy.totalHours / 10;
  }
};

export const round = (num) => {
  return Math.round(num * 10) / 10;
};

export const getValue = (e) => {
  let value;
  switch (e.target.type) {
    case "number":
      if (e.target.value === "") {
        value = "";
      } else {
        value = Number(e.target.value);
      }
      break;
    case "checkbox":
      value = e.target.checked;
      break;
    default:
      value = e.target.value;
  }
  return value;
};

import React, { useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";
import PTOTypeDialog from "./PTOTypeDialog";
import PTOCard from "./PTOCard";
import {
  selectPTOTypesAtom,
  PTOTypesResetAtom,
  schoolYearsObjAtom,
  selectedSchoolYearAtom,
} from "../../recoil/Atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { blankPTOTypeForm } from "../libraries/objects";
import useAddDoc from "../../hooks/useAddDoc";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function PTOTypesContainer() {
  const PTOTypes = useRecoilValue(selectPTOTypesAtom);
  const setPTOTypesReset = useSetRecoilState(PTOTypesResetAtom);
  const [PTOForm, setPTOForm] = useState(blankPTOTypeForm);
  const [open, setOpen] = useState(false);
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const schoolYearsObj = useRecoilValue(schoolYearsObjAtom);
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const [filteredPTOTypes, setFilteredPTOTypes] = useState([]);

  useEffect(() => {
    setFilteredPTOTypes(PTOTypes.filter((PTOType) => PTOType.schoolYear === selectedSchoolYear));
  }, [selectedSchoolYear, PTOTypes]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!PTOForm.id) {
      addDoc({ col: "PTOTypes", data: PTOForm });
    } else {
      const data = { ...PTOForm };
      delete data.id;
      updateDoc({ col: "PTOTypes", id: PTOForm.id, data });
    }
    setPTOTypesReset((prevValue) => {
      return !prevValue;
    });
    setOpen(false);
    setPTOForm(blankPTOTypeForm);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Typography sx={{ pt: 5 }} variant="h4">
        PTO Types
      </Typography>
      <Button
        variant="contained"
        color="tertiary"
        fullWidth
        onClick={handleOpen}
        sx={{ fontSize: 20, mt: 4, padding: 1 }}
      >
        New PTO Type
      </Button>
      {filteredPTOTypes.map((type) => (
        <PTOCard key={type.id} PTOType={type} setPTOForm={setPTOForm} setOpen={setOpen} />
      ))}
      {PTOForm && (
        <PTOTypeDialog
          open={open}
          setOpen={setOpen}
          PTOForm={PTOForm}
          setPTOForm={setPTOForm}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}

import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { schoolYearsResetAtom, schoolYearsAtom } from "../../recoil/Atoms";
import SchoolYearDialog from "./SchoolYearDialog";
import NewSchoolYearButton from "./NewSchoolYearButton";
import SchoolYearCard from "./SchoolYearCard";
import {blankSchoolYearForm} from "../libraries/objects"
import useAddDoc from "../../hooks/useAddDoc";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function SchoolYearsContainer() {
  const schoolYears = useRecoilValue(schoolYearsAtom);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [schoolYearForm, setSchoolYearForm] = useState(blankSchoolYearForm);
  const [isNewSchoolYear, setIsNewSchoolYear] = useState(false);
  const setSchoolYearsReset = useSetRecoilState(schoolYearsResetAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();

  const saveForm = () => {
    if (!schoolYearForm.id) {
      addDoc({ col: "schoolYears", data: schoolYearForm });
    } else {
      const data = {...schoolYearForm};
      delete data.id;
      updateDoc({ col: "schoolYears", id: schoolYearForm.id, data });
    }
    setSchoolYearsReset((prevValue) => {
      return !prevValue;
    });
    setDialogOpen(false);
    setSchoolYearForm(blankSchoolYearForm)
  };

  return (
    <>
      <NewSchoolYearButton setDialogOpen={setDialogOpen} setIsNewSchoolYear={setIsNewSchoolYear} />
      {schoolYears &&
        schoolYears.map((sy) => (
          <SchoolYearCard
            key={sy.id}
            schoolYear={sy}
            setSchoolYearForm={setSchoolYearForm}
            setDialogOpen={setDialogOpen}
            setIsNewSchoolYear={setIsNewSchoolYear}
          />
        ))}
      <SchoolYearDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        schoolYearForm={schoolYearForm}
        setSchoolYearForm={setSchoolYearForm}
        isNewSchoolYear={isNewSchoolYear}
        saveForm={saveForm}
      />
    </>
  );
}

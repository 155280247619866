import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { schoolYearsObjAtom, selectedSchoolYearAtom } from "../../recoil/Atoms";
import TextField from "@mui/material/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { useAuth } from "../../contexts/AuthContext";
import useWindowDimensions from "../../Shared/useWindowDimensions";

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATATABLE_KEY);

export default function DateRangePickers({ form, renderDateRange }) {
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const { currentUser } = useAuth();
  const schoolYearObj = useRecoilValue(schoolYearsObjAtom);
  const [dateLimits, setDateLimits] = useState({ minDate: "01/01/01", maxDate: "12/12/2222" });
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const handleResize = () => {
    if (width > 1000) {
      setIsMobile(false);
    }

    if (width <= 1000) {
      setIsMobile(true);
    }
  };

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedSchoolYear || !currentUser) return;
    if (currentUser.ptoPolicy === "10-Month" || currentUser.ptoPolicy === "10-Month-Non-Exempt" || currentUser.ptoPolicy === "10-Month-Exempt") {
      setDateLimits({
        minDate: DateTime.fromFormat(schoolYearObj[selectedSchoolYear].tenMonthStartDate, "D", {
          locale: "en-US",
        }),
        maxDate: DateTime.fromFormat(schoolYearObj[selectedSchoolYear].tenMonthEndDate, "D", {
          locale: "en-US",
        }),
      });
    }
    if (currentUser.ptoPolicy === "12-Month" || currentUser.ptoPolicy === "12-Month-Leadership" || currentUser.ptoPolicy === "12-Month-Non-Exempt" || currentUser.ptoPolicy === "12-Month-Exempt") {
      setDateLimits({
        minDate: DateTime.fromFormat(schoolYearObj[selectedSchoolYear].twelveMonthStartDate, "D", {
          locale: "en-US",
        }),
        maxDate: DateTime.fromFormat(schoolYearObj[selectedSchoolYear].twelveMonthEndDate, "D", {
          locale: "en-US",
        }),
      });
    }
  }, [selectedSchoolYear, currentUser, schoolYearObj]);

  function disableWeekends(date) {
    return date.weekday === 7 || date.weekday === 6;
  }

  // let minTwoWeeksNotice = new Date(Date.now() + 12096e5);
  // PTOTypes.forEach((type) => {
  //   if (type.id === form.type && type.name === "Advanced Leave") {
  //     if (form.ptoPolicy === "10-Month" && minTwoWeeksNotice < startDate) {
  //       startDate = new Date(startDate.setDate(startDate.getDate() + 14));
  //     } else {
  //       startDate = minTwoWeeksNotice;
  //     }
  //   }
  // });

  return (
    <Box
      sx={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        paddingTop: "1em",
      }}
    >
      {form && (
        <>
          <Typography variant="h4">Select the Date(s) for Your PTO:</Typography>
          <Typography
            variant="h7"
            sx={{
              my: "0.5em",
            }}
          >
            *If you are wanting to request less than a full day, first select the day and then input
            custom hours that you want to request off
          </Typography>
          <Typography
            variant="h7"
            sx={{
              marginBottom: "0.5em",
            }}
          >
            *Minimum notice for advanced leave is two weeks
          </Typography>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <StaticDateRangePicker
              startText="Start of Range"
              endText="End of Range"
              //shouldDisableDate={(day) => isSameDay(day, new Date("2022-09-20"))}
              minDate={dateLimits.minDate}
              maxDate={dateLimits.maxDate}
              value={form.dateRange}
              displayStaticWrapperAs={isMobile ? "mobile" : "desktop"}
              onChange={renderDateRange}
              shouldDisableDate={disableWeekends}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} required onKeyDown={onKeyDown} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} required onKeyDown={onKeyDown} />
                </>
              )}
            />
          </LocalizationProvider>
        </>
      )}
    </Box>
  );
}

import { DateTime } from "luxon";

const getCellDateValue = ({ field, value }) => {
  if (value) {
    const dt = DateTime.fromFormat(value, "D").toMillis();
    return dt;
  } else {
    return 0;
  }
};

const getCellDateFormat = ({ field, value }) => {
  if (value) {
    return DateTime.fromMillis(value).toFormat("D");
  } else {
    return 0;
  }
};

export const requestsColumns = [
  {
    field: "startDate",
    headerName: "Start Date",
    minWidth: 100,
    flex: 2,
    type: "date",
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    valueGetter: getCellDateValue,
    valueFormatter: getCellDateFormat,
  },
  {
    field: "endDate",
    headerName: "endDate",
    minWidth: 100,
    flex: 2,
    type: "date",
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    valueGetter: getCellDateValue,
    valueFormatter: getCellDateFormat,
  },

  {
    field: "requesterName",
    headerName: "Name",
    minWidth: 150,
    flex: 2,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "PTOTypeName",
    headerName: "Type",
    minWidth: 250,
    flex: 2,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "submittedAt",
    headerName: "Submitted",
    minWidth: 100,
    flex: 2,
    type: "date",
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    valueGetter: getCellDateValue,
    valueFormatter: getCellDateFormat,
  },
  {
    field: "hours",
    headerName: "Hours",
    minWidth: 80,
    flex: 1,
    type: "number",
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 150,
    flex: 2,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
];

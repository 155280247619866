import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import useWindowDimensions from "../../Shared/useWindowDimensions";

export const requestsColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 80,
      flex: 1,
      type: "number",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
    },
    {
        field: "lastName",
        headerName: "Last Name",
        minWidth: 80,
        flex: 1,
        type: "number",
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
      {
        field: "id",
        headerName: "Email",
        minWidth: 80,
        flex: 1,
        type: "number",
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
    {
      field: "totalHours",
      headerName: "Total Hours",
      minWidth: 150,
      flex: 2,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
    },
  ];

export default function StaffHoursTable({ staffWithHours }) {
    console.log(staffWithHours)
  const window = useWindowDimensions();

  return (
    <>
      {staffWithHours && (
        <div style={{ height: window.height - 350, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={staffWithHours}
                columns={requestsColumns}
                disableSelectionOnClick
                hover={false}
                getRowClassName={(params) => {
                  const suffix = params.row.status;
                  return `row${suffix}`;
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";
import { Grid, TextField, Typography, Button } from "@mui/material";
import { calcValue, createLabel, calcDisabled, loopArray } from "../libraries/util";
import SavedConfirmation from "./SavedConfirmation";

export default function InputHours({
  payPeriods,
  currentPayPeriod,
  hoursApproved,
  handleHoursChange,
  importantDatesToShow,
  currentPeriodForm,
  handleSave,
  confirmationOpen,
  setConfirmationOpen,
  userRequests,
}) {
  const [focused, setFocused] = useState(false);
  const payPeriod = payPeriods[currentPayPeriod];

  return (
    <>
      {hoursApproved && (
        <Typography textAlign="center" variant="h5" sx={{ mt: 2 }}>
          This Pay Period Locked Because The Hours Have Already Been Approved
        </Typography>
      )}

      <Typography sx={{ mt: 2, mb: 1 }}>Week 1 Hours</Typography>
      <Grid container spacing={2} columns={{ sm: 20, xs: 1 }}>
        {loopArray.map((day, i) => (
          <Grid item sm={4} key={`${day}-${i}`}>
            <TextField
              onChange={handleHoursChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              name={payPeriod.datesArray[i]}
              type="Number"
              label={createLabel({
                day,
                dayOffset: i,
                importantDatesToShow,
                payPeriod,
                userRequests,
              })}
              value={String(calcValue({ currentPeriodForm, payPeriod, dayOffset: i, focused }))}
              fullWidth
              inputProps={{step: ".01", min: "0.00"}}
              disabled={calcDisabled({
                importantDatesToShow,
                payPeriod,
                dayOffset: i,
                hoursApproved,
              })}
            ></TextField>
          </Grid>
        ))}
      </Grid>
      <Typography sx={{ mt: 2, mb: 1 }}>Week 2 Hours</Typography>
      <Grid container spacing={2} columns={{ sm: 20, xs: 1 }}>
        {loopArray.map((day, i) => (
          <Grid item sm={4} key={`${day}-${i + 5}`}>
            <TextField
              onChange={handleHoursChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              name={payPeriod.datesArray[i + 5]}
              type="Number"
              label={createLabel({
                day,
                dayOffset: i+5,
                importantDatesToShow,
                payPeriod,
                userRequests,
              })}
              value={String(calcValue({ currentPeriodForm, payPeriod, dayOffset: i + 5, focused }))}
              fullWidth
              disabled={calcDisabled({
                importantDatesToShow,
                payPeriod,
                dayOffset: i + 5,
                hoursApproved,
              })}
            ></TextField>
          </Grid>
        ))}
      </Grid>
      <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
        Submit
      </Button>
      <SavedConfirmation open={confirmationOpen} setOpen={setConfirmationOpen} />
    </>
  );
}

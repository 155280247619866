import React from "react";
import { useRecoilValue } from "recoil";
import { staffObjAtom } from "../../recoil/Atoms";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useAuth } from "../../contexts/AuthContext";

export default function EmployeeSelect({ form, handleChange, setSelectedStaffPTO }) {
  const { staff } = useAuth();
  const staffObj = useRecoilValue(staffObjAtom);

  const handleEmployeeChange = (e) => {
    handleChange(e);
    setSelectedStaffPTO(staffObj[e.target.value].ptoPolicy);
  };

  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel id="Employee-Select-Label">Select Employee</InputLabel>
      <Select
        labelId="Select Employee"
        id="Employee-Select"
        name="requester"
        value={form.requester}
        label="Select PTO Type"
        required
        onChange={handleEmployeeChange}
      >
        {staff &&
          staff.map((s) => (
            <MenuItem key={s.id} value={s.id}>
              {s.firstName} {s.lastName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

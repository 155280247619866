import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  allHoursAtom,
  allStaffAtom,
  selectedSchoolYearAtom,
  schoolYearsObjAtom,
  allHoursApprovalsAtom,
} from "../../recoil/Atoms";
import useGetPayPeriods from "../../hooks/useGetPayPeriods";
import { DateTime } from "luxon";
import { Typography } from "@mui/material";
import PayPeriodsNavigation from "./PayPeriodsNavigation";
import StaffHoursTable from "./StaffHoursTable";

export default function HoursReportContainer() {
  const allHours = useRecoilValue(allHoursAtom);
  const allStaff = useRecoilValue(allStaffAtom);
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const schoolYearsObj = useRecoilValue(schoolYearsObjAtom);
  const allHoursApprovals = useRecoilValue(allHoursApprovalsAtom);
  const [payPeriods, setPayPeriods] = useState();
  const [currentPayPeriod, setCurrentPayPeriod] = useState(0);
  const [staffWithHours, setStaffWithHours] = useState();
  const { getPayPeriods } = useGetPayPeriods();

  useEffect(() => {
    if (!schoolYearsObj || !selectedSchoolYear) return;
    const firstWeek = DateTime.fromFormat(schoolYearsObj[selectedSchoolYear].firstPayWeek, "D");
    const currentWeekNumber = DateTime.now().weekNumber;
    setCurrentPayPeriod(Math.floor((currentWeekNumber - firstWeek.weekNumber) / 2));
    setPayPeriods(getPayPeriods({ firstWeek, currentWeekNumber }));
  }, [schoolYearsObj, selectedSchoolYear, getPayPeriods]);

  useEffect(() => {
    if (!allHours || !allStaff || !allHoursApprovals || !payPeriods) return;
    const tempArray = [];
    allStaff.forEach((staff) => {
      const newStaff = { ...staff, hours: {}, approvedHours: {}, totalHours: 0 };
      if (staff.nonExempt) {
        allHours.forEach((hours) => {
          if (hours.id === staff.id && staff.nonExempt) {
            newStaff["hours"] = hours;
          }
        });
        allHoursApprovals.forEach((approvals) => {
          if (approvals.id === staff.id && staff.nonExempt) {
            newStaff["approvedHours"] = approvals;
          }
        });
        if (!payPeriods[currentPayPeriod]) return;
        let counter = 0;
        payPeriods[currentPayPeriod].datesArray.forEach((date) => {
          counter += +newStaff.hours[date] || 0;
        });
        newStaff.totalHours = counter;

        tempArray.push(newStaff);
      }
    });
    setStaffWithHours(tempArray);
  }, [allHours, allStaff, allHoursApprovals, payPeriods, currentPayPeriod]);

  return (
    <>
      <Typography sx={{ pt: 2, mb: 1 }} variant="h3">
        Review Hour Submissions
      </Typography>
      {payPeriods && (
        <>
          <PayPeriodsNavigation
            payPeriod={payPeriods[currentPayPeriod]}
            setCurrentPayPeriod={setCurrentPayPeriod}
          />
          {staffWithHours && <StaffHoursTable staffWithHours={staffWithHours} />}
        </>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { schoolYearsObjAtom, selectedSchoolYearAtom, currentUserAtom } from "../../recoil/Atoms";
import { Typography, Box } from "@mui/material";
import { DateTime } from "luxon";
import useGetPayPeriods from "../../hooks/useGetPayPeriods";
import PayPeriodsNavigation from "./PayPeriodsNavigation";
import InputHoursContainer from "./InputHoursContainer";
import HoursLogsContainer from "../../Shared/HoursLogs/HoursLogsContainer";

export default function HoursContainer() {
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const schoolYearsObj = useRecoilValue(schoolYearsObjAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const [payPeriods, setPayPeriods] = useState();
  const [currentPayPeriod, setCurrentPayPeriod] = useState(0);
  const [selectedStaff, setSelectedStaff] = useState();
  const [periodKey, setPeriodKey] = useState();
  const { getPayPeriods } = useGetPayPeriods();

  //TODO: began working on making it so admin can change who the user is here to edit other people's hours, need to now fix InputHoursContainer, but haven't begun that process

  useEffect(() => {
    if (!schoolYearsObj || !selectedSchoolYear) return;
    const firstWeek = DateTime.fromFormat(schoolYearsObj[selectedSchoolYear].firstPayWeek, "D");
    const currentWeekNumber = DateTime.now().weekNumber;
    const tempPeriods = getPayPeriods({
      firstWeek,
      selectedSchoolYearObj: schoolYearsObj[selectedSchoolYear],
    });
    setPayPeriods(tempPeriods);
    if (DateTime.now().toMillis() - firstWeek.toMillis() < 0) {
      setCurrentPayPeriod(0);
    } else {
      setCurrentPayPeriod(Math.floor((currentWeekNumber + 52 - firstWeek.weekNumber) / 2) % 26);
    }
  }, [schoolYearsObj, selectedSchoolYear, getPayPeriods]);

  useEffect(() => {
    if (!currentUser) return;
    setSelectedStaff(currentUser.id);
  }, [currentUser]);

  useEffect(() => {
    if (!payPeriods || !currentPayPeriod) return;
    console.log(payPeriods);
    const payPeriod = payPeriods[currentPayPeriod];
    setPeriodKey(`${payPeriod.year}-week${payPeriod.weekOneNumber}`);
  }, [payPeriods, currentPayPeriod]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2, pb: 2 }}>
        <Typography sx={{ textAlign: "center", pt: 2, marginBottom: "10px" }} variant="h2">
          My Hours
        </Typography>
      </Box>
      {payPeriods && (
        <>
          <PayPeriodsNavigation
            payPeriod={payPeriods[currentPayPeriod]}
            setCurrentPayPeriod={setCurrentPayPeriod}
          />
          <InputHoursContainer
            payPeriods={payPeriods}
            currentPayPeriod={currentPayPeriod}
            selectedStaff={selectedStaff}
          />
          {currentUser && <HoursLogsContainer currentPeriod={periodKey} staffId={currentUser.id} />}
        </>
      )}
    </>
  );
}

export const getCurrentPeriodPTO = ({ userRequests, payPeriod }) => {
  const ptoObj = {};
  userRequests.forEach((request) => {
    if (request?.status !== "approved") return;
    const [startDay, endDay] = request.dateRange;
    let PTOStarted = false;
    payPeriod.datesArray.forEach((date) => {
      if (startDay === date) {
        PTOStarted = true;
      }
      if (PTOStarted) {
        ptoObj[date] = request.fullDay ? "Full Day PTO" : "Partial Day PTO";
        if (endDay === date) {
          PTOStarted = false;
        }
      }
    });
  });
  return ptoObj;
};

export const getImportantDatesObj = ({ importantDates, payPeriod }) => {
  const holidayObj = {};
  importantDates.forEach((importantDate) => {
    if (!importantDate?.holiday) return;
    const [startDay, endDay] = importantDate.date;
    let holidayStarted = false;
    payPeriod?.datesArray.forEach((date) => {
      if (startDay === date) {
        holidayStarted = true;
      }
      if (holidayStarted) {
        holidayObj[date] = importantDate.name;
        if (endDay === date) {
          holidayStarted = false;
        }
      }
    });
  });
  return holidayObj;
};

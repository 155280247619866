import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";

export default function SchoolYearCard({
  schoolYear,
  setSchoolYearForm,
  setDialogOpen,
}) {
  const handleEdit = () => {
    setSchoolYearForm(schoolYear);
    setDialogOpen(true);
  };
  return (
    <Card sx={{ mt: 2 }}>
      <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontWeight: 400, marginLeft: 3 }}>{schoolYear.name}</Typography>
        <Button size="small" variant="contained" onClick={handleEdit}>
          Edit
        </Button>
      </CardContent>
    </Card>
  );
}

import React from "react";
import { useRecoilValue } from "recoil";
import { schoolYearsAtom, selectedSchoolYearAtom } from "../../recoil/Atoms";
import { Box, TextField, Typography, Alert } from "@mui/material";
import PartialDay from "./PartialDay";
import DateRangePickers from "./DateRangePickers";
import CustomHoursPicker from "./CustomHoursPicker";
import PTOTypeSelect from "./PTOTypeSelect";
import PTORequestSchoolYear from "./PTORequestSchoolYear";
import EmployeeSelect from "./EmployeeSelect";

export default function RequestForm({
  source,
  form,
  setForm,
  handleChange,
  renderDateRange,
  selectedStaffPTO,
  setSelectedStaffPTO,
  conflicts,
}) {
  const schoolYears = useRecoilValue(schoolYearsAtom);
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  return (
    <Box sx={{ minWidth: 120, mt: 2 }}>
      {form && (
        <>
          <Typography>Select Calendar Year:</Typography>
          <PTORequestSchoolYear
            schoolYears={schoolYears}
            form={form}
            setForm={setForm}
            handleChange={handleChange}
          />
          {source === "admin" && (
            <EmployeeSelect
              form={form}
              setForm={setForm}
              handleChange={handleChange}
              setSelectedStaffPTO={setSelectedStaffPTO}
            />
          )}
          <PTOTypeSelect
            form={form}
            handleChange={handleChange}
            selectedStaffPTO={selectedStaffPTO}
          />
          <TextField
            fullWidth
            style={{ marginBottom: "1em", marginTop: "1em" }}
            id="reason"
            name="reason"
            label="Reason for PTO"
            multiline
            rows={4}
            onChange={handleChange}
          />
          <DateRangePickers
            selectedSchoolYear={selectedSchoolYear}
            schoolYears={schoolYears}
            form={form}
            setForm={setForm}
            handleChange={handleChange}
            renderDateRange={renderDateRange}
          />
          {conflicts &&
            conflicts.map((conflict) => (
              <Alert severity="error" key={conflict} sx={{ mt: 1, mb: 1 }}>
                {`These dates conflict with the following: ${conflict}`}
              </Alert>
            ))}
          <CustomHoursPicker form={form} setForm={setForm} handleChange={handleChange} />
          <PartialDay form={form} setForm={setForm} handleChange={handleChange} />
        </>
      )}
    </Box>
  );
}

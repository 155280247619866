import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function MobileMenu({ handleClose, setAnchorEl, anchorEl, permission }) {
  const { currentUser } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div>
      <IconButton
        size="large"
        color="inherit"
        onClick={handleClick}
        aria-controls="scollapse-menu"
        aria-haspopup="true"
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>
      {currentUser && (
        <Menu
          id="collapse-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link to="/home" className="navLinkMenu">
              Profile
            </Link>
          </MenuItem>
          {currentUser && currentUser.nonExempt && (
            <MenuItem onClick={handleClose}>
              <Link to="/hours" className="navLinkMenu">
                Hours
              </Link>
            </MenuItem>
          )}
          {(currentUser.permission === "admin" || currentUser.permission === "superAdmin") && (
            <MenuItem onClick={handleClose}>
              <Link to="/requests" className="navLinkMenu">
                Requests
              </Link>
            </MenuItem>
          )}
          {(currentUser.permission === "admin" || currentUser.permission === "superAdmin") && (
            <MenuItem onClick={handleClose}>
              <Link to="/settings" className="navLinkMenu">
                Settings
              </Link>
            </MenuItem>
          )}
          <MenuItem onClick={handleClose}>
            <Link to="/calendar" className="navLinkMenu">
              Calendar
            </Link>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}

import React from "react";
import { Typography } from "@material-ui/core";
import SchoolYearsContainer from "./SchoolYearsContainer";
import PTOTypesContainer from "./PTOTypesContainer";

export default function SettingsContainer() {
  return (
    <>
      <Typography sx={{ textAlign: "center", pt: 2, marginBottom: "10px" }} variant="h2">
        Settings
      </Typography>
      <Typography sx={{ pt: 2 }} variant="h4">
        School Years
      </Typography>
      <SchoolYearsContainer />
      <PTOTypesContainer />
    </>
  );
}

import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function PTORequestSchoolYear({ schoolYears, handleChange, form }) {
  return (
    <Box sx={{ minWidth: 120, marginBottom: "15px" }}>
      <FormControl fullWidth>
        <Select
          id="school-year-select"
          value={form.schoolYear}
          onChange={handleChange}
          name="schoolYear"
        >
          {schoolYears.map((schoolYear) => (
            <MenuItem key={schoolYear.id} value={schoolYear.id}>
              {schoolYear.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

import React from "react";
import Card from "@mui/material/Card";
import { useRecoilValue } from "recoil";
import { schoolYearsObjAtom } from "../../recoil/Atoms";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function PTOCard({ PTOType, setPTOForm, setOpen }) {
  const schoolYearsObj = useRecoilValue(schoolYearsObjAtom);

  const handleEdit = () => {
    setPTOForm(PTOType)
    setOpen(true);
  };

  return (
    <>
      {schoolYearsObj && (
        <Card sx={{ minWidth: 275, mt: 3 }}>
          <CardContent>
            <Typography variant="h5">{PTOType.name}</Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Total Hours Per Year: </b>
              {PTOType.totalHours}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>School Year: </b>
              {schoolYearsObj[PTOType.schoolYear].name}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Advanced Notice Required: </b>
              {PTOType.notice}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Policy: </b>
              {PTOType.policy}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Total Hours of Possible Rollover: </b>
              {PTOType.rollover}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Accrual: </b>
              {PTOType.accrual ? "Yes" : "No"}
            </Typography>
          </CardContent>
          <CardActions>
            <Button sx={{ mt: 2 }} size="small" variant="contained" onClick={handleEdit}>
              Edit
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentUserHoursAtom,
  currentUserAtom,
  currentUserHoursResetAtom,
  selectImportantDatesAtom,
  allHoursApprovalsAtom,
  selectUserRequestsAtom,
} from "../../recoil/Atoms";
import { Box } from "@mui/material";
import useHoursLogger from "../../hooks/useHoursLogger";
import useSetDoc from "../../hooks/useSetDoc";
import InputHours from "./InputHours";

export default function InputHoursContainer({ payPeriods, currentPayPeriod, selectedStaff }) {
  const { sendRequest: setDoc } = useSetDoc();
  const setCurrentUserHoursReset = useSetRecoilState(currentUserHoursResetAtom);
  const currentUserHours = useRecoilValue(currentUserHoursAtom);
  const importantDates = useRecoilValue(selectImportantDatesAtom);
  const allHoursApprovals = useRecoilValue(allHoursApprovalsAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const userRequests = useRecoilValue(selectUserRequestsAtom);
  const [currentPeriodForm, setCurrentPeriodForm] = useState();
  const [importantDatesToShow, setImportantDatesToShow] = useState();
  const [periodKey, setPeriodKey] = useState();
  const [hoursApproved, setHoursApproved] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { submitLog } = useHoursLogger();

  const payPeriod = payPeriods[currentPayPeriod];

  useEffect(() => {
    if (!payPeriod || !allHoursApprovals || !currentUser) return;
    setPeriodKey(`${payPeriod.year}-week${payPeriod.weekOneNumber}`);
    const currentApprovals = allHoursApprovals.find((approvals) => approvals.id === currentUser.id);
    if (currentApprovals && currentApprovals[periodKey]) {
      setHoursApproved(currentApprovals[periodKey]);
    } else {
      setHoursApproved(false);
    }
  }, [payPeriod, allHoursApprovals, currentUser, periodKey]);

  useEffect(() => {
    if (!importantDates || !currentPeriodForm) return;
    const tempObj = {};
    importantDates.forEach((event) => {
      event.datesArray.forEach((date) => {
        if (currentPeriodForm[date] !== undefined) {
          tempObj[date] = { name: event.name, holiday: event.holiday, blackout: event.blackout };
        }
      });
    });
    setImportantDatesToShow(tempObj);
  }, [importantDates, currentPeriodForm, currentPayPeriod]);

  useEffect(() => {
    if (!currentUser) return;
    let tempObj = {};
    if (!currentUserHours) {
      tempObj.id = currentUser.id;
    } else {
      tempObj = { ...currentUserHours };
    }
    payPeriod.datesArray.forEach((date) => {
      if (!tempObj[date] && tempObj[date] !== 0) {
        tempObj[date] = "";
      }
    });
    setCurrentPeriodForm(tempObj);
  }, [currentUser, currentUserHours, payPeriod]);

  const handleHoursChange = (e) => {
    setCurrentPeriodForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
  };

  const handleSave = async () => {
    await setDoc({ col: "hours", data: currentPeriodForm, id: currentUser.id });
    setCurrentUserHoursReset((pV) => !pV);
    setConfirmationOpen(true);
    submitLog({ hours: currentPeriodForm, user: currentUser.id, dates: payPeriod.datesArray, periodKey: periodKey });
  };

  return (
    <Box sx={{ mt: 2, pt: 2 }}>
      {importantDatesToShow && currentPeriodForm && periodKey && (
        <InputHours
          payPeriods={payPeriods}
          currentPayPeriod={currentPayPeriod}
          hoursApproved={hoursApproved}
          handleHoursChange={handleHoursChange}
          importantDatesToShow={importantDatesToShow}
          currentPeriodForm={currentPeriodForm}
          handleSave={handleSave}
          confirmationOpen={confirmationOpen}
          setConfirmationOpen={setConfirmationOpen}
          userRequests={userRequests}
        />
      )}
    </Box>
  );
}

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

export default function SuperAdminRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  console.log(currentUser);

  return (
    <>
      {currentUser && (
        <Route
          {...rest}
          render={(props) => {
            return currentUser.permission === "superAdmin" ? (
              <Component {...props} />
            ) : (
              <Redirect to="/home" />
            );
          }}
        ></Route>
      )}
    </>
  );
}

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import HistoryCard from "./HistoryCard"

export default function RequestDetails({selectedRow}) {



  return (
    <>
      {selectedRow && (
        <Box>
          <Typography variant="h3">
          Admin History
        </Typography>
        {selectedRow.history.map((submission)=>(
            <HistoryCard key={submission.statusTimestamp} submission={submission} />
        ))}
          <Divider />
        </Box>
      )}
    </>
  );
}

import { useEffect, useState } from "react";
import { Typography, TextField } from "@mui/material";
import { useRecoilValue } from "recoil";
import { allRequestsAtom, importantDatesAtom } from "../../recoil/Atoms";
import { getCurrentPeriodPTO, getImportantDatesObj } from "../libraries/util";

function DateHoursCell({ staff, payPeriod, day, index, locked, editToggle, hours, setHours }) {
  const [currentPTO, setCurrentPTO] = useState({});
  const [currentHolidays, setCurrentHolidays] = useState({});
  console.log(index)

  const currentDate = payPeriod?.datesArray[index] ?? "";

  const allRequests = useRecoilValue(allRequestsAtom);
  const importantDates = useRecoilValue(importantDatesAtom);

  useEffect(() => {
    if (!allRequests || !staff) return;
    const userRequests = allRequests.filter((request) => request.requester === staff.id);
    const ptoObj = getCurrentPeriodPTO({ userRequests, payPeriod });
    setCurrentPTO(ptoObj);
  }, [allRequests, staff, payPeriod]);

  useEffect(() => {
    if (!importantDates) return;
    const holidaysObj = getImportantDatesObj({ importantDates, payPeriod });
    setCurrentHolidays(holidaysObj);
  }, [importantDates, payPeriod]);

  const handleHoursChange = (event) => {
    setHours({ ...hours, [currentDate]: event.target.value });
  };

  return (
    <>
      <Typography component="p">
        <b>{day}</b>: {currentDate}
      </Typography>
      <Typography component="p">
        <b>Hours</b>:{hours[currentDate]}
      </Typography>
      {editToggle && (
        <TextField
          type="Number"
          disabled={locked}
          value={hours[currentDate]}
          onChange={handleHoursChange}
          inputProps={{ step: ".01", min: "0.00" }}
        />
      )}
      <Typography variant="subText" component="p">
        {currentPTO[currentDate] && currentPTO[currentDate]}
      </Typography>
      <Typography variant="subText" component="p">
        {currentHolidays[currentDate] && currentHolidays[currentDate]}
      </Typography>
    </>
  );
}

export default DateHoursCell;

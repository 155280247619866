export const blankSchoolYearForm = {
  name: "",
  tenMonthStartDate: "",
  twelveMonthStartDate: "",
  tenMonthEndDate: "",
  twelveMonthEndDate: "",
  active: true,
  firstPayWeek: "",
};

export const blankPTOTypeForm = {
  name: "",
  notice: 0,
  rollover: 0,
  totalHours: 0,
  accrual: false,
  schoolYear: "",
  policy: "",
};

export const blankDateForm = {
  name: "",
  date: [null, null],
  blackout: false,
  holiday: false,
  startDate: null,
};

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import RequestForm from "./RequestForm";
import { blankForm } from "../libraries/objects";

export default function SubmitRequestDialog({
  source,
  open,
  setOpen,
  form,
  setForm,
  handleSubmit,
  renderDateRange,
  handleChange,
  selectedStaffPTO,
  setSelectedStaffPTO,
  conflicts,
  setConflicts
}) {
  const handleClose = () => {
    setOpen(false);
    setForm(blankForm);
    setConflicts(null);

  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
      <form
        onSubmit={(e) => {
          handleSubmit(e, source);
        }}
      >
        <Typography variant="h2" sx={{ padding: 2 }}>
          New Submit PTO Request
        </Typography>
        <DialogContent>
          <RequestForm
            source={source}
            form={form}
            setForm={setForm}
            handleSubmit={handleSubmit}
            renderDateRange={renderDateRange}
            handleChange={handleChange}
            setSelectedStaffPTO={setSelectedStaffPTO}
            selectedStaffPTO={selectedStaffPTO}
            conflicts={conflicts}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit"> Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

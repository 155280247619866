import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  allRequestsResetAtom,
  allStaffAtom,
  currentUserAtom,
  selectAllRequestsAtom,
  statusesAtom,
} from "../../recoil/Atoms";
import { Typography, FormControlLabel, Switch, Fab, Box, Select, MenuItem } from "@mui/material";
import RequestsTable from "./RequestsTable";
import AddIcon from "@mui/icons-material/Add";
import RequestDialog from "./RequestDialog";
import RequestContainer from "../../RequestDialog/components/RequestContainer";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { DateTime } from "luxon";

const defaultView = { id: "all", firstName: "All", lastName: "Staff" };

export default function RequestsContainer() {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setResetAllRequests = useSetRecoilState(allRequestsResetAtom);
  const [requestOpen, setRequestOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [pendingOnly, setPendingOnly] = useState(false);
  const [rows, setRows] = useState();
  const [adminNotes, setAdminNotes] = useState("");
  const [selectedRow, setSelectedRow] = useState();
  const currentUser = useRecoilValue(currentUserAtom);
  const allStaff = useRecoilValue(allStaffAtom);
  const [staffForFilter, setStaffForFilter] = useState();
  const [selectedStaff, setSelectedStaff] = useState("all");
  const allRequests = useRecoilValue(selectAllRequestsAtom);
  const statuses = useRecoilValue(statusesAtom);

  useEffect(() => {
    if (!allStaff) return;
    setStaffForFilter([defaultView, ...allStaff]);
  }, [allStaff]);

  useEffect(() => {
    if (!selectedRow) return;
    setAdminNotes(selectedRow.adminNotes);
  }, [selectedRow]);

  const handleOpenRequest = () => {
    setRequestOpen(true);
  };

  const handleCheck = () => {
    setPendingOnly((pV) => !pV);
  };

  const handleStatusChange = async (e) => {
    if (!selectedRow) return;
    await updateDoc({
      col: "PTORequests",
      data: {
        ...selectedRow,
        adminNotes: adminNotes,
        status: e.target.name,
        approver: currentUser.id,
        decisionMadeAt: DateTime.now().toLocaleString("en-US"),
      },
      id: selectedRow.id,
    });
    setResetAllRequests((pV) => !pV);
    setDetailsOpen(false);
  };

  const handleStaffChange = (e) => {
    setSelectedStaff(e.target.value);
  };

  useEffect(() => {
    if (!allRequests) return;
    const filteredByPending = pendingOnly
      ? allRequests.filter((request) => request.status === statuses.pending)
      : allRequests;
    const filteredByUser =
      selectedStaff === "all"
        ? filteredByPending
        : filteredByPending.filter((request) => request.requester === selectedStaff);
    setRows(filteredByUser);
  }, [allRequests, pendingOnly, statuses, setRows, selectedStaff]);

  return (
    <>
      <Typography sx={{ pt: 2, marginBottom: "10px" }} variant="h3">
        Review PTO Requests
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
        <FormControlLabel
          control={<Switch checked={pendingOnly} onChange={handleCheck} />}
          label="Pending Only"
        />
        {selectedStaff && (
          <Select onChange={handleStaffChange} name="selectedStaff" value={selectedStaff}>
            {staffForFilter &&
              staffForFilter.map((staff) => (
                <MenuItem value={staff.id} key={staff.id}>
                  {`${staff.firstName} ${staff.lastName}`}
                </MenuItem>
              ))}
          </Select>
        )}
      </Box>
      <RequestsTable
        setSelectedRow={setSelectedRow}
        setOpen={setDetailsOpen}
        pendingOnly={pendingOnly}
        rows={rows}
        setRows={setRows}
      />
      {selectedRow && (
        <RequestDialog
          selectedRow={selectedRow}
          open={detailsOpen}
          setOpen={setDetailsOpen}
          adminNotes={adminNotes}
          setAdminNotes={setAdminNotes}
          handleStatusChange={handleStatusChange}
        />
      )}
      <Fab
        sx={{ position: "fixed", bottom: "5vh", right: "5vw" }}
        color="primary"
        aria-label="add"
        onClick={handleOpenRequest}
      >
        <AddIcon />
      </Fab>
      <RequestContainer source="admin" open={requestOpen} setOpen={setRequestOpen} />
    </>
  );
}

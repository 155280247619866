import { DateTime } from "luxon";
import { monthsArray, monthsObj } from "./objects";

document.addEventListener("wheel", function (event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

export const round = (exp, digit) => {
  return Math.round(exp * (10 * digit)) / (10 * digit);
};

export const getResetYear = (restMonth) => {};

export const createSummaryTable = (snapshot, userData, PTOTypes) => {
  const rows = [];
  userData = { startMonth: 10, startYear: 2021 };
  PTOTypes.forEach((PTOType, i) => {
    const requestArray = [];
    snapshot.forEach((request) => {
      if (request.data().type === PTOType.id) {
        requestArray.push({ id: request.id, ...request.data() });
      }
    });
    const usedAndFuture = calcUsedAndFutureHours(requestArray);
    const possibleAndAccrued = possibleAndAccruedHours(requestArray, PTOType, userData);

    const borrowedHours = round(possibleAndAccrued.accrued - usedAndFuture.used, 1);
    const remainingHours = round(
      possibleAndAccrued.possible - (usedAndFuture.used + usedAndFuture.future),
      1
    );
    rows.push({
      type: PTOType.name,
      id: PTOType.name,
      possibleHours: possibleAndAccrued.possible,
      accruedHours: possibleAndAccrued.accrued,
      usedHours: usedAndFuture.used,
      futureApprovedHours: usedAndFuture.future,
      remainingHours: remainingHours,
      borrowedHours: borrowedHours < 0 ? borrowedHours : 0,
    });
  });
  return rows;
};

const calcUsedAndFutureHours = (requests) => {
  const obj = {
    used: 0,
    future: 0,
  };
  requests.forEach((request) => {
    if (request.status && request.status === "approved") {
      const totalMilli = Date.parse(request.dateRange[1]) - Date.parse(request.dateRange[0]);
      const totalDays = totalMilli / 60 / 60 / 24 / 1000 + 1;
      const hours = request.fullDay ? totalDays * 8 : Number(request.hours);
      const requestStart = Date.parse(request.dateRange[0]);
      const currentDate = Date.now();

      if (requestStart > currentDate) {
        obj.future += hours;
      } else {
        obj.used += hours;
      }
    }
  });
  return obj;
};

export const possibleAndAccruedHours = (requests, PTOType, userData) => {
  const returnObj = {
    possible: 0,
    accrued: 0,
  };
  // if not accrual, total and accrued are the total hours in settings
  if (!PTOType.accrual) {
    returnObj.possible = PTOType.totalHours;
    returnObj.accrued = PTOType.totalHours;
    return returnObj;
  } else {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const acStartMonth = monthsObj[PTOType.resetMonth];
    const acStartYear = acStartMonth > currentMonth ? currentYear - 1 : currentYear;

    const accrualObj = {};
    PTOType.accrualArray.forEach((m) => {
      accrualObj[m.name] = m.hours;
    });

    //total months employeed since start of accural start (starting before accural date = neg number)
    let diff = (userData.startYear - acStartYear) * 12 + userData.startMonth - acStartMonth;
    // if they've been employeed longer that start of accrual, the get all the periods
    diff = diff < 1 ? 0 : diff;
    const compMonth = currentMonth < acStartMonth ? currentMonth + 12 : currentMonth;
    for (let i = acStartMonth + diff; i < acStartMonth + 12; i++) {
      const month = i < 13 ? i : i - 12;
      const h = Number(accrualObj[monthsArray[month - 1]]);
      returnObj.possible += h;
      if (i <= compMonth) {
        returnObj.accrued += h;
      }
    }
    return returnObj;
  }
};

export const setRequestDates = (r) => {
  let submitSort = 0;
  let submitDate = "7/01/2021";

  if (r.data().submittedAt) {
    submitSort = r.data().submittedAt.toMillis();
    const submitDT = DateTime.fromMillis(submitSort);
    submitDate = submitDT.toFormat("M/dd/yyyy");
  }

  const startSort = Date.parse(r.data().dateRange[0]);
  const startDT = DateTime.fromMillis(startSort);
  const startDate = startDT.toFormat("M/dd/yyyy");

  const endSort = Date.parse(r.data().dateRange[1]);
  const endDT = DateTime.fromMillis(endSort);
  const endDate = endDT.toFormat("M/dd/yyyy");

  return {
    submitSort,
    submitDate,
    startSort,
    startDate,
    endSort,
    endDate,
  };
};

export const getValue = (e) => {
  let value;
  switch (e.target.type) {
    case "number":
      if (e.target.value === "") {
        value = "";
      } else {
        value = Number(e.target.value);
      }
      break;
    case "checkbox":
      value = e.target.checked;
      break;
    case "radio":
      value = e.target.value === "fullDay" ? true : false;
      break;
    default:
      value = e.target.value;
  }
  return value;
};

export const checkImportantDates = ({ importantDates, dateRange }) => {
  const [ptoStart, ptoEnd] = dateRange;
  const conflictArray = [];
  if (!ptoStart || !ptoEnd) return;
  const dtPTOStart = DateTime.fromFormat(ptoStart, "D");
  const dtPTOEnd = DateTime.fromFormat(ptoEnd, "D");

  importantDates.forEach((importantDate) => {
    const [importantDateStart, importantDateEnd] = importantDate.date;
    const dtImportantDateStart = DateTime.fromFormat(importantDateStart, "D");
    const dtImportantDateEnd = DateTime.fromFormat(importantDateEnd, "D");
    if (
      (dtImportantDateStart >= dtPTOStart && dtImportantDateStart <= dtPTOEnd) ||
      (dtPTOStart >= dtImportantDateStart && dtPTOStart <= dtImportantDateEnd) ||
      (dtImportantDateEnd >= dtPTOStart && dtImportantDateEnd <= dtPTOEnd) ||
      (dtPTOEnd >= dtImportantDateStart && dtPTOEnd <= dtImportantDateEnd)
    ) {
      conflictArray.push(`${importantDate.name}: ${importantDateStart} - ${importantDateEnd}`);
    }
  });
  return conflictArray.length > 0 ? conflictArray : null;
};

import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { allHoursLogsAtom, currentUserAtom } from "../../recoil/Atoms";
import { useRecoilValue } from "recoil";
import HoursLog from "./HoursLog";

export default function HoursLogsContainer({ currentPeriod, staffId }) {
  const [currentLogs, setCurrentLogs] = useState([]);
  const allLogs = useRecoilValue(allHoursLogsAtom);
  const currentUser = useRecoilValue(currentUserAtom);

  useEffect(() => {
    if (!allLogs || !currentUser) return;
    const tempLogs = allLogs.filter(
      (log) => log.staffId === staffId && log.period === currentPeriod
    );
    setCurrentLogs(tempLogs);
  }, [currentUser, allLogs, currentPeriod, staffId]);
  return (
    <>
      {currentLogs.length > 0 && (
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">History</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {currentLogs.map((log) => (
              <HoursLog key={log.id} message={log.message} />
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export default function PartialDay({ form, handleChange }) {
  return (
    <>
      {!form.fullDay && (
        <Box
          sx={{
            textAlign: "left",
            alignItems: "left",
            paddingTop: "1em",
          }}
        >
          <TextField
            fullWidth
            style={{ marginBottom: "1em", marginTop: "1em" }}
            id="hours"
            name="hours"
            value={form.hours}
            type="number"
            label="Hours Requested Off"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              step: "any",
            }}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            style={{ marginBottom: "1em", marginTop: "1em" }}
            id="additionalInfo"
            name="additionalInfo"
            label="Please share when you will Arrive and/or Leave"
            multiline
            rows={4}
            onChange={handleChange}
          />
        </Box>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { staffObjAtom, allStaffAtom, currentUserAtom } from "../../recoil/Atoms";
import { Box, Typography, Select, MenuItem } from "@mui/material";
import PTOSummaryContainer from "../../PTOSummary/components/PTOSummaryContainer";

export default function StaffViewContainer() {
  const allStaff = useRecoilValue(allStaffAtom);
  const staffObj = useRecoilValue(staffObjAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const [selectedStaff, setSelectedStaff] = useState();

  const handleStaffChange = (e) => {
    setSelectedStaff(e.target.value);
  };

  useEffect(() => {
    if (!currentUser) return;
    if (!selectedStaff) {
      setSelectedStaff(currentUser.id);
    }
  }, [currentUser, selectedStaff]);

  return (
    <>
      {selectedStaff && (
        <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
          <Typography sx={{ textAlign: "center", pt: 2, marginBottom: "10px" }} variant="h2">
            Staff Details
          </Typography>
          <Select onChange={handleStaffChange} name="selectedStaff" value={selectedStaff}>
            {allStaff &&
              allStaff.map((staff) => (
                <MenuItem value={staff.id} key={staff.id}>
                  {`${staff.firstName} ${staff.lastName}`}
                </MenuItem>
              ))}
          </Select>
        </Box>
      )}
      {staffObj && <PTOSummaryContainer user={staffObj[selectedStaff]} />}
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { schoolYearsAtom, selectedSchoolYearAtom, currentUserAtom } from "../../recoil/Atoms";
import { Select, MenuItem } from "@mui/material";

export default function WebAdmins() {
  const schoolYears = useRecoilValue(schoolYearsAtom);
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const setSelectedSchoolYear = useSetRecoilState(selectedSchoolYearAtom);
  const currentUser = useRecoilValue(currentUserAtom);

  const handleYearChange = (e) => {
    setSelectedSchoolYear(e.target.value);
  };

  return (
    <>
      {currentUser && selectedSchoolYear && (
        <>
          <Link to="/home" className="navLink">
            Profile
          </Link>
          {currentUser && currentUser.nonExempt && (
            <Link to="/hours" className="navLink">
              Hours
            </Link>
          )}
          {(currentUser.permission === "admin" || currentUser.permission === "superAdmin") && (
            <>
              <Link to="/requests" className="navLink">
                Requests
              </Link>
              <Link to="/reviewhours" className="navLink">
                Review Hours
              </Link>
              <Link to="/staffview" className="navLink">
                Staff
              </Link>
              <Link to="/settings" className="navLink">
                Settings
              </Link>
            </>
          )}
          <Link to="/calendar" className="navLink">
            Calendar
          </Link>
          <Select id="year-select" value={selectedSchoolYear} onChange={handleYearChange}>
            {schoolYears &&
              schoolYears.map((schoolYear) => (
                <MenuItem value={schoolYear.id} key={schoolYear.id}>
                  {schoolYear.name}
                </MenuItem>
              ))}
          </Select>
        </>
      )}
    </>
  );
}

import React from "react";
import Container from "@material-ui/core/Container";
import NavBar from "../../Shared/Navigation/Navbar";
import ProfileContainer from "../components/ProfileContainer";

export default function Profile() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: "64px" }}>
        <ProfileContainer />
      </Container>
    </>
  );
}

import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#26C6F5",
        contrastText: "#fff",
      },
      secondary: {
        main: "#B930E6",
      },
      tertiary: {
        main: "#F6E649",
        contrastText: "#555",
      },
      error: {
        main: "#EB3D3D",
      },
      pending: {
        main: "#ccc",
        contrastText: "#333",
      },
      unpaid: {
        main: "#711919",
        contrastText: "#fff",
      },
      approved: {
        main: "#26C6F5",
        contrastText: "#fff",
      },
      denied: {
        main: "#EB3D3D",
        contrastText: "#fff",
      },
      cancelled: {
        main: "#B930E6",
        contrastText: "#fff",
      },
    },
    selectLabel: { display: "show" },
    typography: {
      fontFamily: "Urbanist, Arial",
      h2: {
        fontSize: 46,
        color: "#26C6F5",
      },
      h4: {
        color: "#26C6F5",
        fontSize: 30,
      },
      h5: {
        fontSize: 26,
        fontWeight: 800,
      },
      h6: {
        fontSize: 18,
        fontWeight: 800,
      },
      body1: {
        fontSize: 18,
        fontWeight: 300,
      },
      body2: {
        fontSize: 15,
        fontWeight: 300,
        color: "#444",
      },
      cardSection: {
        fontSize: 26,
        fontWeight: 200,
        color: "#222",
      },
      cardLabel: {
        fontSize: 18,
        fontWeight: 800,
        color: "#222",
      },
      subText: {
        fontSize: 14,
        fontWeight: 400,
        color: "#333",
        fontStyle: "italic",
      },
    },
  })
);

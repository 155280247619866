import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { CSVLink } from "react-csv";


export default function PayPeriodsNavigation({ setCurrentPayPeriod, payPeriod, hoursCSVHeader, hoursCSV }) {
  const handlePeriodChange = (e) => {
    const change = e.target.name === "increment" ? 1 : -1;
    setCurrentPayPeriod((pV) => {
      if (pV + change < 0) {
        return 0;
      } else if (pV + change > 26) {
        return 26;
      } else {
        return pV + change;
      }
    });
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h5">{payPeriod.label}</Typography>
      <Box>
        <CSVLink data={hoursCSV} headers={hoursCSVHeader} style={{textDecoration: "none"}} filename={`${payPeriod.label} Approved Hours Export`}>
          <Button variant="outlined" sx={{textDecoration: "none", mr: 2}} >
            Export CSV
          </Button>
        </CSVLink>
        <Button name="decrement" variant="outlined" onClick={handlePeriodChange}>
          Pervious Period
        </Button>
        <Button name="increment" sx={{ ml: 2 }} variant="outlined" onClick={handlePeriodChange}>
          Next Period
        </Button>
      </Box>
    </Box>
  );
}

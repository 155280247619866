import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Card, CardContent, Box } from "@mui/material";
import useSetDoc from "../../hooks/useSetDoc";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import useHoursLogger from "../../hooks/useHoursLogger";
import { useSetRecoilState } from "recoil";
import { allHoursApprovalsResetAtom, allHoursResetAtom } from "../../recoil/Atoms";
import DateHoursCell from "./DateHoursCell";
import HoursLogsContainer from "../../Shared/HoursLogs/HoursLogsContainer";
const loopArray = ["Mon: ", "Tues: ", "Wed: ", "Thur: ", "Fri: "];
const weekCounter = [1, 2];

export default function StaffHoursRow({ staff, payPeriod }) {
  const { sendRequest: setDoc } = useSetDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { submitLog } = useHoursLogger();
  const setAllHoursApprovalsReset = useSetRecoilState(allHoursApprovalsResetAtom);
  const setAllHoursReset = useSetRecoilState(allHoursResetAtom);
  const [periodKey, setPeriodKey] = useState();
  const [totalHours, setTotalHours] = useState();
  const [locked, setLocked] = useState(false);
  const [editToggle, setEditToggle] = useState(false);
  const [hours, setHours] = useState({});

  useEffect(() => {
    if (!staff || !periodKey) return;
    setLocked(staff.approvedHours[periodKey] ? true : false);
  }, [staff, periodKey]);

  useEffect(() => {
    if (!payPeriod) return;
    setPeriodKey(`${payPeriod.year}-week${payPeriod.weekOneNumber}`);
  }, [payPeriod]);

  useEffect(() => {
    if (!staff || !payPeriod) return;
    const tempObj = {};
    payPeriod.datesArray.forEach((date) => {
      tempObj[date] = staff?.hours[date] ?? "";
    });
    setHours(tempObj);
  }, [staff, payPeriod]);

  useEffect(() => {
    if (!payPeriod) return;
    let counter = 0;
    payPeriod.datesArray.forEach((date) => {
      counter += +staff.hours[date] || 0;
    });
    setTotalHours(Math.round(counter * 10) / 10);
  }, [payPeriod, staff]);

  const handleApprove = (e) => {
    const key = e.target.name;
    if (!staff.approvedHours.id) {
      setDoc({ col: "hoursApprovals", data: { [key]: true }, id: staff.id });
    } else if (staff.approvedHours[key]) {
      updateDoc({ col: "hoursApprovals", data: { [key]: false }, id: staff.id });
    } else {
      updateDoc({ col: "hoursApprovals", data: { [key]: true }, id: staff.id });
    }
    setAllHoursApprovalsReset((pV) => !pV);
  };

  const handleEditToggle = () => {
    setEditToggle((pV) => !pV);
  };

  const handleSave = async () => {
    const newHours = { ...staff.hours, ...hours };
    await setDoc({ col: "hours", data: newHours, id: staff.id });
    setEditToggle((pV) => !pV);
    setAllHoursReset((pV) => !pV);
    submitLog({ hours: hours, user: staff.id, dates: payPeriod.datesArray, periodKey: periodKey });
  };

  return (
    <Card sx={{ mt: 2, backgroundColor: locked ? "#eee" : "#fff" }}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant={"h4"}>{`${staff.firstName} - ${totalHours} Hours`}</Typography>
          <Box>
            {!editToggle && (
              <Button disabled={locked} variant="outlined" onClick={handleEditToggle}>
                Edit
              </Button>
            )}
            {editToggle && (
              <>
                <Button sx={{ mr: 2 }} disabled={locked} variant="outlined" onClick={handleSave}>
                  Save
                </Button>
                <Button disabled={locked} variant="outlined" onClick={handleEditToggle}>
                  Cancel
                </Button>
              </>
            )}
          </Box>
        </Box>
        {weekCounter.map((weekNumber) => (
          <div key={weekNumber}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Week {weekNumber} Hours
            </Typography>
            <Grid container spacing={2} columns={{ sm: 20, xs: 1 }}>
              {loopArray.map((day, i) => (
                <Grid item sm={4} key={`${day}-${i + (weekNumber-1)*5}`}>
                  <DateHoursCell
                    staff={staff}
                    payPeriod={payPeriod}
                    day={day}
                    index={i + (weekNumber-1)*5}
                    locked={locked}
                    editToggle={editToggle}
                    hours={hours}
                    setHours={setHours}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
        <Button
          sx={{ mt: 2 }}
          fullWidth
          color={staff.approvedHours[periodKey] ? "pending" : "secondary"}
          variant="contained"
          onClick={handleApprove}
          name={periodKey}
          value={staff.approvedHours[periodKey]}
        >
          {staff.approvedHours[periodKey] ? "Undo Approval" : "Approve"}
        </Button>
        <HoursLogsContainer currentPeriod={periodKey} staffId={staff.id} />
      </CardContent>
    </Card>
  );
}

import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function CustomHoursPicker({ form, handleChange }) {
  return (
    <FormControl component="fieldset" sx={{ mt: 2 }}>
      <FormLabel component="legend">Full Day</FormLabel>
      <RadioGroup
        aria-label="Full Day"
        defaultValue="fullDay"
        name="fullDay"
        value={form.fullDay ? "fullDay" : "partialDay"}
        onChange={handleChange}
      >
        <FormControlLabel value="fullDay" control={<Radio />} label="Full Day" />
        <FormControlLabel
          value="partialDay"
          control={<Radio disabled={(form.hours > 8 || form.hours === 0)} />}
          label="Custom Hours"
        />
      </RadioGroup>
    </FormControl>
  );
}

import React, { useState } from "react";
import { Grid, TextField, Button, Box, FormGroup, FormControlLabel, Switch } from "@mui/material";
import { importantDatesResetAtom } from "../../recoil/Atoms";
import { useSetRecoilState } from "recoil";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker from "@mui/lab/DateRangePicker";

export default function ImportantDate({ date }) {
  const [dateForm, setDateForm] = useState(date);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setImportantDatesReset = useSetRecoilState(importantDatesResetAtom);

  const handleTextEdit = (e) => {
    setDateForm((pV) => ({ ...pV, name: e.target.value }));
  };

  const handleDateEdit = (range) => {
    const start = range[0] ? range[0].toLocaleString("en-US") : null;
    const end = range[1] ? range[1].toLocaleString("en-US") : null;
    setDateForm((pV) => {
      return { ...pV, date: [start, end], startDate: Number(range[0].toMillis()) };
    });
  };

  const handleSwitch = (e) => {
    setDateForm((pV) => {
      return { ...pV, [e.target.name]: e.target.checked };
    });
  };

  const handleSave = () => {
    updateDoc({ col: "importantDates", data: dateForm, id: dateForm.id });
    setImportantDatesReset((pV) => !pV);
  };

  return (
    <Grid container spacing={2} sx={{ mb: 4 }}>
      <Grid item sm={3}>
        <TextField
          name="name"
          value={dateForm.name}
          fullWidth
          onChange={handleTextEdit}
        ></TextField>
      </Grid>
      <Grid item sm={6}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DateRangePicker
            startText="Start of Range"
            endText="End of Range"
            value={dateForm.date}
            onChange={handleDateEdit}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} required />
                <Box sx={{ mx: 2 }}>-</Box>
                <TextField {...endProps} required />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item sm={2}>
        <FormGroup>
          <FormControlLabel
            control={<Switch name="blackout" checked={dateForm.blackout} onChange={handleSwitch} />}
            label="Blackout"
          />
          <FormControlLabel
            control={<Switch name="holiday" checked={dateForm.holiday} onChange={handleSwitch} />}
            label="Holiday"
          />
        </FormGroup>
      </Grid>
      <Grid item sm={1}>
        <Button
          sx={{ flexGrow: 1, height: 1 }}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSave}
        >
          Update Date
        </Button>
      </Grid>
    </Grid>
  );
}

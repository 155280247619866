import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import SuperAdminRoute from "./SuperAdminRoute";
import Profile from "./Profile/pages/Profile";
import UploadPTO from "./Uploads/pages/UploadPTO";
import Requests from "./Reports/pages/Requests";
import Settings from "./Settings/pages/Settings";
import Calendar from "./Reports/pages/Calendar";
import Login from "./auth/Login";
import StaffView from "./StaffView/pages/StaffView";
import Hours from "./Hours/pages/Hours";
import ReviewHours from "./ReviewHours/pages/ReviewHours";
import HoursReport from "./HoursReport/pages/HoursReport";
import SummaryTablePage from "./SummaryTable/pages/SummaryTablePage";

export const routes = (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/login" component={Login} />
    <PrivateRoute exact path="/home" component={Profile} />
    <PrivateRoute exact path="/hours" component={Hours} />
    <PrivateRoute exact path="/uploadPTO" component={UploadPTO} />
    <SuperAdminRoute exact path="/requests" component={Requests} />
    <AdminRoute exact path="/settings" component={Settings} />
    <SuperAdminRoute exact path="/reviewhours" component={ReviewHours} />
    <AdminRoute path="/staffview" component={StaffView} />
    <AdminRoute path="/hoursReport" component={HoursReport} />
    <PrivateRoute exact path="/calendar" component={Calendar} />
    <PrivateRoute exact path="/summary-table" component={SummaryTablePage} />
  </Switch>
);

import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { requestsColumns } from "../libraries/table";
import useWindowDimensions from "../../Shared/useWindowDimensions";

export default function RequestsTable({ setSelectedRow, setOpen, pendingOnly, rows, setRows }) {
  const window = useWindowDimensions();



  const handleRowClick = (data) => {
    setSelectedRow(data.row);
    setOpen(true);
  };

  return (
    <Box sx={{ mt: 2 }}>
      {rows && (
        <div style={{ height: window.height - 350, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={rows}
                columns={requestsColumns}
                disableSelectionOnClick
                onRowClick={handleRowClick}
                hover={false}
                getRowClassName={(params) => {
                  const suffix = params.row.status;
                  return `row${suffix}`;
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Box>
  );
}

import { useEffect } from "react";
import useGetDocs from "./hooks/useGetDocs";
import useGetDoc from "./hooks/useGetDoc";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  schoolYearsAtom,
  PTOTypesAtom,
  schoolYearsResetAtom,
  schoolYearsObjAtom,
  PTOTypesResetAtom,
  PTOTypesObjAtom,
  allRequestsAtom,
  allRequestsResetAtom,
  importantDatesAtom,
  importantDatesResetAtom,
  selectedSchoolYearAtom,
  rolloverObjAtom,
  rolloverResetAtom,
  currentUserHoursAtom,
  currentUserAtom,
  currentUserHoursResetAtom,
  allHoursAtom,
  allHoursResetAtom,
  allHoursApprovalsAtom,
  allHoursApprovalsResetAtom,
  allHoursLogsAtom,
  hoursLogsResetAtom,
} from "./recoil/Atoms";

const InitialRequests = () => {
  const { sendRequest: getDocs } = useGetDocs();
  const { sendRequest: getDoc } = useGetDoc();
  const setSchoolYears = useSetRecoilState(schoolYearsAtom);
  const setSchoolYearsObj = useSetRecoilState(schoolYearsObjAtom);
  const setPTOTypesObj = useSetRecoilState(PTOTypesObjAtom);
  const setPTOTypes = useSetRecoilState(PTOTypesAtom);
  const setAllHoursLogs = useSetRecoilState(allHoursLogsAtom);
  const setAllRequests = useSetRecoilState(allRequestsAtom);
  const setImportantDates = useSetRecoilState(importantDatesAtom);
  const setRolloverObj = useSetRecoilState(rolloverObjAtom);
  const setCurrentUserHours = useSetRecoilState(currentUserHoursAtom);
  const setAllHours = useSetRecoilState(allHoursAtom);
  const setAllHoursApprovals = useSetRecoilState(allHoursApprovalsAtom);
  const schoolYearsReset = useRecoilValue(schoolYearsResetAtom);
  const PTOTypesReset = useRecoilValue(PTOTypesResetAtom);
  const allRequestsReset = useRecoilValue(allRequestsResetAtom);
  const importantDatesReset = useRecoilValue(importantDatesResetAtom);
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const rolloverReset = useRecoilValue(rolloverResetAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const currentUserHoursReset = useRecoilValue(currentUserHoursResetAtom);
  const allHoursReset = useRecoilValue(allHoursResetAtom);
  const allHoursApprovalsReset = useRecoilValue(allHoursApprovalsResetAtom);
  const hoursLogsReset = useRecoilValue(hoursLogsResetAtom);

  useEffect(() => {
    const getSchoolYears = async () => {
      const [arr, obj] = await getDocs({ col: "schoolYears" });
      setSchoolYears(arr);
      setSchoolYearsObj(obj);
    };
    getSchoolYears();
  }, [setSchoolYears, getDocs, schoolYearsReset, setSchoolYearsObj]);

  useEffect(() => {
    const getPTOTypes = async () => {
      const [arr, obj] = await getDocs({ col: "PTOTypes" });
      setPTOTypes(arr);
      setPTOTypesObj(obj);
    };
    getPTOTypes();
  }, [setPTOTypes, getDocs, PTOTypesReset, setPTOTypesObj]);

  useEffect(() => {
    const getAllResults = async () => {
      const [arr] = await getDocs({
        col: "PTORequests",
        config: { orderBy: ["createdAt", "desc"] },
      });
      setAllRequests(arr);
    };
    getAllResults();
  }, [setAllRequests, getDocs, allRequestsReset]);

  useEffect(() => {
    if (!selectedSchoolYear) return;
    const getImportantDates = async () => {
      const [arr] = await getDocs({
        col: "importantDates",
        config: { where: ["schoolYear", "==", selectedSchoolYear], orderBy: ["startDate"] },
      });
      setImportantDates(arr);
    };
    getImportantDates();
  }, [selectedSchoolYear, setImportantDates, getDocs, importantDatesReset]);

  useEffect(() => {
    const getRollover = async () => {
      const tempResults = await getDocs({ col: "rollover" });
      setRolloverObj(tempResults[1]);
    };
    getRollover();
  }, [getDocs, rolloverReset, setRolloverObj]);

  useEffect(() => {
    if (!currentUser) return;
    const getHours = async () => {
      const tempResults = await getDoc({ col: "hours", id: currentUser.id });
      setCurrentUserHours(tempResults);
    };
    getHours();
  }, [setCurrentUserHours, currentUser, getDoc, currentUserHoursReset]);

  useEffect(() => {
    const getAllHours = async () => {
      const [arr] = await getDocs({ col: "hours" });
      setAllHours(arr);
    };
    getAllHours();
  }, [allHoursReset, getDocs, setAllHours]);

  useEffect(() => {
    const getAllHoursApprovals = async () => {
      const [arr] = await getDocs({ col: "hoursApprovals" });
      setAllHoursApprovals(arr);
    };
    getAllHoursApprovals();
  }, [setAllHoursApprovals, getDocs, allHoursApprovalsReset]);

  useEffect(() => {
    const getAllHoursLogs = async () => {
      const [arr] = await getDocs({ col: "hoursLogs", config: {orderBy: ["submittedOn", "desc"]}  });
      setAllHoursLogs(arr);
    };
    getAllHoursLogs();
  }, [getDocs, setAllHoursLogs, hoursLogsReset]);

  return null;
};

export default InitialRequests;

import {
  allRequestsAtom,
  staffObjAtom,
  PTOTypesObjAtom,
  selectAllRequestsAtom,
  currentUserAtom,
  importantDatesAtom,
} from "./Atoms";
import {flow, map,tap, nth, first, join, get as getFp, compact} from "lodash/fp";
import { DateTime } from "luxon";
import { PieChart } from "@material-ui/icons";

const getReadableDate = (timestamp) => {
  const dt = DateTime.fromMillis(timestamp.toMillis());
  return dt.toLocaleString("en-us");
};

export const selectAllRequestsGetter = ({ get }) => {
  const allRequests = get(allRequestsAtom);
  const staffObj = get(staffObjAtom);
  const PTOTypesObj = get(PTOTypesObjAtom);
  if (!allRequests || !staffObj || !PTOTypesObj) return;

  const selectDateRange = getFp("dateRange");
  const second = nth(1);
  const selectStartDate = flow(selectDateRange, first);
  const selectEndDate = flow(selectDateRange, second);
  const concatName = flow(compact, join(" "))
  const formatName = (firstName, lastName) => concatName([firstName, lastName])

  const selectStaffFirstName = (x) => staffObj[x.requester]?.firstName || "";
  const selectStaffLastName = (x) => staffObj[x.requester]?.lastName || "";
  const selectPTOTypeName = (x) => PTOTypesObj[x.PTOType]?.name;
  const selectRequesterName = (x) => formatName(selectStaffFirstName(x), selectStaffLastName(x))
  const selectSubmittedAt = (x) => getReadableDate(x.createdAt)

  const selectAllRequests = flow(
    map((requestPTO) => ({
      ...requestPTO,
      startDate: selectStartDate(requestPTO),
      endDate: selectEndDate(requestPTO),
      requesterName: selectRequesterName(requestPTO),
      PTOTypeName: selectPTOTypeName(requestPTO),
      submittedAt: selectSubmittedAt(requestPTO),
    }))
  )
  return selectAllRequests(allRequests)
};

export const selectUserRequestsGetter = ({ get }) => {
  const allRequests = get(selectAllRequestsAtom);
  const currentUser = get(currentUserAtom);
  if (!allRequests) return;
  return allRequests.filter((request) => request.requester === currentUser.id);
};

export const importantDatesGetter = ({ get }) => {
  const importantDates = get(importantDatesAtom);
  if (!importantDates) return;
  const tempArray = [];
  importantDates.forEach((date) => {
    const dtStart = DateTime.fromFormat(date.date[0], "D");
    const dtEnd = DateTime.fromFormat(date.date[1], "D");
    const deEndforCal = dtEnd.plus({ days: 1 });
    const diff = dtEnd.diff(dtStart, "day").values.days;
    const datesArray = [];
    for (let i = 0; i <= diff; i++) {
      datesArray.push(dtStart.plus({ days: i }).toFormat("D"));
    }

    const start = dtStart.toFormat("yyyy-MM-dd");
    const end = deEndforCal.toFormat("yyyy-MM-dd");
    let color;
    let textColor;
    if (date.holiday) {
      color = "#F6E649";
      textColor = "#333";
    } else if (date.blackout) {
      color = "#222";
      textColor = "#fff";
    } else {
      color = "#666";
      textColor = "#fff";
    }
    const tempObj = {
      ...date,
      title: date.name,
      start: start,
      end: end,
      allday: true,
      backgroundColor: color,
      borderColor: color,
      textColor: textColor,
      datesArray: datesArray,
    };
    tempArray.push(tempObj);
  });

  return tempArray;
};

export const payPeriodsGetter = ({ get }) => {
  const allRequests = get(selectAllRequestsAtom);
  const currentUser = get(currentUserAtom);
  if (!allRequests) return;
  return allRequests.filter((request) => request.requester === currentUser.id);
};

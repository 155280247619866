import React from "react";
import { useRecoilValue } from "recoil";
import { PTOPoliciesAtom } from "../../recoil/Atoms";
import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Select, MenuItem } from "@mui/material";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import PTOSchoolYear from "./PTOSchoolYear";
import { getValue } from "../libraries/functions";

export default function PTOTypeForm({ PTOForm, setPTOForm, handleSubmit }) {
  const accrualLabel = PTOForm.accrual ? "Accrual" : "No Accrual";
  const PTOPolicies = useRecoilValue(PTOPoliciesAtom);

  const handleChange = (e) => {
    const value = getValue(e);
    setPTOForm((pV) => {
      return { ...pV, [e.target.name]: value };
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography>PTO Type Name</Typography>
      <TextField
        required
        fullWidth
        id="pto-name"
        type="text"
        name="name"
        variant="outlined"
        onChange={handleChange}
        value={PTOForm.name}
      />
      <Typography>Policy</Typography>
      <Select required fullWidth onChange={handleChange} name="policy" value={PTOForm.policy ?? ""}>
        {PTOPolicies &&
          PTOPolicies.map((policy) => (
            <MenuItem value={policy} key={policy}>
              {policy}
            </MenuItem>
          ))}
      </Select>
      <Typography sx={{ mt: 2 }}>School Year</Typography>
      <PTOSchoolYear PTOForm={PTOForm} handleChange={handleChange} />
      <Typography sx={{ mt: 2 }}>Number of Days Notice Required</Typography>
      <TextField
        required
        fullWidth
        id="notice-required"
        type="Number"
        name="notice"
        variant="outlined"
        onChange={handleChange}
        value={String(PTOForm.notice)}
      />
      <Typography sx={{ mt: 2 }}>Number of Hours Available for Rollover</Typography>
      <TextField
        required
        fullWidth
        id="roll-over"
        type="number"
        min="0"
        step="1"
        name="rollover"
        variant="outlined"
        onChange={handleChange}
        value={String(PTOForm.rollover)}
      />
      <Typography sx={{ mt: 2 }}>Total Hours Per Year</Typography>
      <TextField
        required
        fullWidth
        id="hours-per-year"
        type="number"
        min="0"
        name="totalHours"
        value={String(PTOForm.totalHours)}
        variant="outlined"
        onChange={handleChange}
      />
      <FormControlLabel
        control={<Switch checked={PTOForm.accrual} onChange={handleChange} name="accrual" />}
        label={accrualLabel}
      />
      <Button sx={{ mt: 2 }} variant="contained" type="submit" fullWidth>
        Submit
      </Button>
    </form>
  );
}

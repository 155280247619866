import React, { useState, useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentUserAtom, allRequestsResetAtom, statusesAtom } from "../../recoil/Atoms";
import { Button } from "@mui/material";
import PTORequests from "./PTORequests";
import RequestContainer from "../../RequestDialog/components/RequestContainer";
import PTOSummaryContainer from "../../PTOSummary/components/PTOSummaryContainer";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function ProfileContainer() {
  const [requestOpen, setRequestOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const handleOpen = () => setRequestOpen(true);
  const [cancelRequestId, setCancelRequestId] = useState();
  const currentUser = useRecoilValue(currentUserAtom);
  const statuses = useRecoilValue(statusesAtom);
  const setAllRequestsReset = useSetRecoilState(allRequestsResetAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();

  const handleCancel = useCallback(
    async (id) => {
      await updateDoc({ col: "PTORequests", id: id, data: { status: statuses.cancelled } });
      setCancelRequestId(null);
      setCancelOpen(false);
      setAllRequestsReset((pV) => !pV);
    },
    [setAllRequestsReset, statuses, updateDoc]
  );

  return (
    <>
      <Button
        variant="contained"
        color="tertiary"
        fullWidth
        onClick={handleOpen}
        sx={{ fontSize: "1.5rem", mt: 4, padding: 2 }}
      >
        Submit PTO Request
      </Button>
      <PTORequests
        handleCancel={handleCancel}
        cancelOpen={cancelOpen}
        setCancelOpen={setCancelOpen}
        setCancelRequestId={setCancelRequestId}
        cancelRequestId={cancelRequestId}
      />
      <PTOSummaryContainer user={currentUser} />
      <RequestContainer source="profile" open={requestOpen} setOpen={setRequestOpen} />
    </>
  );
}

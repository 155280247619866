import * as React from "react";
import Button from "@mui/material/Button";

export default function NewSchoolYearButton({ setDialogOpen, setIsNewSchoolYear }) {

  const handleOpen = () => {
    setIsNewSchoolYear(true);
    setDialogOpen(true);
  };
  return (
    <Button
      variant="contained"
      color="tertiary"
      fullWidth
      onClick={handleOpen}
      sx={{ fontSize: 20, mt: 4, padding: 1, mb: 1 }}
    >
      New Calendar Year
    </Button>
  );
}

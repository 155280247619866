import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function CancelConfirmation({
  open,
  setOpen,
  handleCancel,
  setCancelRequestId,
  cancelRequestId,
}) {
  const handleClose = () => {
    setOpen(false);
    setCancelRequestId(null);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are You Sure You Want to Cancel This PTO Request</DialogTitle>
        <DialogContent>
          <DialogContentText>This action can not be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Nevermind</Button>
          <Button onClick={() => handleCancel(cancelRequestId)}>
            Yes, Cancel This PTO Request
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

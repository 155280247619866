import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { PTOTypesAtom } from "../../recoil/Atoms";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function PTOTypeSelect({ form, selectedStaffPTO, handleChange }) {
  const PTOTypes = useRecoilValue(PTOTypesAtom);

  const availableTypes = useMemo(() => {
    if (!PTOTypes || !selectedStaffPTO) return;
    return PTOTypes.filter((type) => type.policy === selectedStaffPTO && type.schoolYear === form.schoolYear);
  }, [PTOTypes, selectedStaffPTO, form.schoolYear]);

  return (
    <FormControl fullWidth>
      <InputLabel id="PTOTypeLabel">Select PTO Type</InputLabel>
      <Select
        labelId="Select PTO Type"
        id="PTOTypeSelect"
        name="PTOType"
        value={form.PTOType}
        label="Select PTO Type"
        required
        onChange={handleChange}
      >
        {availableTypes &&
          availableTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { selectAllRequestsAtom } from "../../recoil/Atoms";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import useGetSummary from "../../hooks/useGetSummary";
import { getURL } from "../libraries/functions";
import Rollover from "./Rollover";

export default function SummaryCard({ PTOType, user, rolloverObj, request }) {
  const [summary, setSummary] = useState();
  const [rolloverAmount, setRolloverAmount] = useState(null);
  const { getSummary } = useGetSummary();
  const allRequests = useRecoilValue(selectAllRequestsAtom);
  const url = getURL();

  useEffect(() => {
    if (!rolloverObj || !user || !PTOType) return;
    let amount = 0;
    if (rolloverObj[user.id] && rolloverObj[user.id][PTOType.id]) {
      amount = rolloverObj[user.id][PTOType.id];
    }
    setRolloverAmount(amount);
  }, [rolloverObj, user, PTOType]);

  useEffect(() => {
    if (!user || !allRequests || rolloverAmount === null) return;
    const tempSummary = getSummary({ user, allRequests, PTOType, rolloverAmount, request });
    setSummary(tempSummary);
  }, [user, getSummary, allRequests, PTOType, rolloverAmount, request]);

  return (
    <Grid item md={6} sm={6} xs={12} sx={{ mt: 2 }}>
      {summary && (
        <Card>
          <CardContent>
            <Typography variant="h5">{PTOType.name}</Typography>
            {request && (
              <>
                <Typography sx={{ mt: 2 }}>
                  <b>Accrued as of Request Date: </b> {summary.accruedOnRequestDate}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  <b>Available as of Request Date: </b> {summary.availableOnRequestDate}
                </Typography>
              </>
            )}
            <Typography sx={{ mt: 2 }}>
              <b>Employee Start Date: </b> {user.startDate}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Hours {PTOType.accrual ? "Accrued To-Date" : "Available From Policy"} </b>{" "}
              {summary.accruedHours}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Rollover Hours </b> {rolloverAmount}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Paid Hours Already Used: </b> {summary.usedHours}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Paid and Unpaid Hours Already Used: </b> {summary.totalHoursMissed}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Future Hours Approved: </b> {summary.scheduledHours}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Hours Available Right Now: </b> {summary.availableHours}
            </Typography>
            <Typography variant="subText">
              The hours you've accrued, plus rollover hours, minus the hours you've already taken.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Total To Accrue: </b> {summary.totalToAccrue}
            </Typography>
            <Typography variant="subText">
              The total hours you will accrue this school year once the school year is over.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Total Approved: </b> {summary.totalApproved}
            </Typography>
            <Typography variant="subText">
              This is the total approved hours of PTO, both past and future.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <b>Total Left: </b> {summary.totalRemaining}
            </Typography>
            {url && url === "staffview" && (
              <Rollover PTOType={PTOType} selectedStaff={user} rolloverObj={rolloverObj} />
            )}
          </CardContent>
        </Card>
      )}
    </Grid>
  );
}

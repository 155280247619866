import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Link, useHistory } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Badge from "@material-ui/core/Badge";
import { useAuth } from "../../contexts/AuthContext";
import useWindowDimensions from "../useWindowDimensions";
import MobileMenu from "./MobileMenu";
import WebMenu from "./WebMenu";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { schoolYearsAtom, selectedSchoolYearAtom } from "../../recoil/Atoms";

export default function NavBar() {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const schoolYears = useRecoilValue(schoolYearsAtom);
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const setSelectedSchoolYear = useSetRecoilState(selectedSchoolYearAtom);
  const { logout, authUser } = useAuth();

  useEffect(() => {
    if (!schoolYears || selectedSchoolYear) return;
    try {
      setSelectedSchoolYear(schoolYears[0].id);
    } catch (error) {
      console.error(
        "[schoolYears Error] schoolYears[0].id is undefined eventhough schoolYears is no longer null. schoolYears is: ",
        schoolYears,
        " and the error is: ",
        error
      );
    }
  }, [selectedSchoolYear, schoolYears, setSelectedSchoolYear]);

  const handleResize = () => {
    if (width > 1000) {
      setCollapse(false);
    }

    if (width <= 1000) {
      setCollapse(true);
    }
  };

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      history.push("login");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box id="menuNav" sx={{ flexGrow: 1 }}>
      <AppBar sx={{ displayPrint: "none" }} position="fixed">
        <Toolbar>
          {collapse && (
            <MobileMenu handleClose={handleClose} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
          )}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            TGP PTO System
          </Typography>
          {!collapse && <WebMenu />}
          {authUser ? (
            <IconButton aria-label="logout" color="inherit" onClick={handleLogout}>
              <Badge color="secondary">
                <ExitToAppIcon />
              </Badge>
            </IconButton>
          ) : (
            <Link to="/login" className="navLink">
              Staff Login
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

import { useState, useCallback } from "react";
import { currentUserAtom, hoursLogsResetAtom } from "../recoil/Atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";

const useHoursLogger = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useRecoilValue(currentUserAtom);
  const setHoursLogsReset = useSetRecoilState(hoursLogsResetAtom);

  const submitLog = useCallback(
    async ({ hours, user, dates, periodKey }) => {
      if (!currentUser) return;
      setIsLoading(true);
      setError(null);

      let dateDisplay = "";
      dates.forEach((date) => {
        dateDisplay += `<li class="log-hours-list-item">${date}: ${
          hours[date] ? hours[date] : "0"
        } hours</li> `;
      });
      try {
        const message = `<div class="log-container">At ${new Date().toLocaleTimeString()} on ${new Date().toDateString()} <b>${
          currentUser.id
        }</b> updated <b>${user}'s</b> hours to be</div><div class="log-hours-list-container"><ul class="log-hours-list"> ${dateDisplay}</ul></div>`;
        const data = {
          submittedOn: Timestamp.now(),
          message: message,
          editorId: currentUser.id,
          staffId: user,
          period: periodKey,
          active: true,
        };
        const docRef = collection(db, "hoursLogs");
        await addDoc(docRef, data);
        setHoursLogsReset((pV) => !pV);
      } catch (err) {
        setError(err.message || "Something went wrong!");
        console.log(err);
      }
      setIsLoading(false);
    },
    [currentUser, setHoursLogsReset]
  );
  return { isLoading, error, submitLog };
};

export default useHoursLogger;

import React from "react";
import { useRecoilValue } from "recoil";
import { statusesAtom } from "../../recoil/Atoms";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export default function ApproveDenyButtons({ selectedRow, handleStatusChange }) {
  const statuses = useRecoilValue(statusesAtom);
  return (
    <Box sx={{ minWidth: 120, mt: 2, flexGrow: 1 }}>
      {selectedRow && (
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            {selectedRow.status === statuses.approved && (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                name={statuses.pending}
                onClick={handleStatusChange}
              >
                Undo Approval
              </Button>
            )}
            {selectedRow.status !== statuses.approved && (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleStatusChange}
                name={statuses.approved}
              >
                Approve
              </Button>
            )}
          </Grid>
          <Grid item sm={4} xs={12}>
            {selectedRow.status === statuses.denied && (
              <Button
                fullWidth
                color="error"
                variant="contained"
                name={statuses.pending}
                onClick={handleStatusChange}
              >
                Undo Denial
              </Button>
            )}
            {selectedRow.status !== statuses.denied && (
              <Button
                fullWidth
                color="error"
                variant="contained"
                onClick={handleStatusChange}
                name={statuses.denied}
              >
                Deny
              </Button>
            )}
          </Grid>
          <Grid item sm={4} xs={12}>
            {selectedRow.status === statuses.unpaid && (
              <Button
                fullWidth
                color="error"
                variant="contained"
                name={statuses.pending}
                onClick={handleStatusChange}
              >
                Undo Unpaid
              </Button>
            )}
            {selectedRow.status !== statuses.unpaid && (
              <Button
                fullWidth
                color="unpaid"
                variant="contained"
                name={statuses.unpaid}
                onClick={handleStatusChange}
              >
                Unpaid Taken
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

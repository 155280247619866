import React from "react";
import Container from "@material-ui/core/Container";
import NavBar from "../../Shared/Navigation/Navbar";
import ReviewHoursContainer from "../components/ReviewHoursContainer";

export default function ReviewHours() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: "64px" }}>
        <ReviewHoursContainer />
      </Container>
    </>
  );
}

import React from "react";
import { useRecoilValue } from "recoil";
import { staffObjAtom } from "../../recoil/Atoms";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

export default function HistoryCard({ submission }) {
  const staffObj = useRecoilValue(staffObjAtom)
  const statusMillis = submission.statusTimestamp.toMillis();
  const statusDT = DateTime.fromMillis(statusMillis);
  const statusTimestamp = `${statusDT.toFormat("M/dd/yyyy")}  ${statusDT.toLocaleString(
    DateTime.TIME_SIMPLE
  )}`;

  return (
    <Card sx={{ margin: 2, backgroundColor: "#fcfcfc" }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <b>Admin: </b>
          {staffObj[submission.statusAdmin]}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          <b>Marked Status: </b>
          {submission.status}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          <b>Timestamp: </b>
          {statusTimestamp}
        </Typography>
        <Typography variant="body2">
          {submission.adminNotes}
        </Typography>
      </CardContent>
    </Card>
  );
}

import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  allHoursAtom,
  allStaffAtom,
  selectedSchoolYearAtom,
  schoolYearsObjAtom,
  allHoursApprovalsAtom,
} from "../../recoil/Atoms";
import useGetPayPeriods from "../../hooks/useGetPayPeriods";
import { DateTime } from "luxon";
import { Typography } from "@mui/material";
import StaffHoursRow from "./StaffHoursRow";
import PayPeriodsNavigation from "./PayPeriodsNavigation";

export default function ReviewHoursContainer() {
  const allHours = useRecoilValue(allHoursAtom);
  const allStaff = useRecoilValue(allStaffAtom);
  const selectedSchoolYear = useRecoilValue(selectedSchoolYearAtom);
  const schoolYearsObj = useRecoilValue(schoolYearsObjAtom);
  const allHoursApprovals = useRecoilValue(allHoursApprovalsAtom);
  const [payPeriods, setPayPeriods] = useState();
  const [currentPayPeriod, setCurrentPayPeriod] = useState(0);
  const [staffWithHours, setStaffWithHours] = useState();
  const { getPayPeriods } = useGetPayPeriods();
  const [hoursCSV, setHoursCSV] = useState();
  const [hoursCSVHeader, setHoursCSVHeader] = useState();

  useEffect(() => {
    if (!schoolYearsObj || !selectedSchoolYear) return;
    const firstWeek = DateTime.fromFormat(schoolYearsObj[selectedSchoolYear].firstPayWeek, "D");
    const currentWeekNumber = DateTime.now().weekNumber;
    setCurrentPayPeriod(Math.floor((currentWeekNumber + 52 - firstWeek.weekNumber) / 2) % 26);
    setPayPeriods(
      getPayPeriods({ firstWeek, selectedSchoolYearObj: schoolYearsObj[selectedSchoolYear] })
    );
  }, [schoolYearsObj, selectedSchoolYear, getPayPeriods]);

  useEffect(() => {
    if (!allHours || !allStaff || !allHoursApprovals) return;
    const tempArray = [];
    allStaff.forEach((staff) => {
      const newStaff = { ...staff, hours: {}, approvedHours: {} };
      if (staff.nonExempt) {
        allHours.forEach((hours) => {
          if (hours.id === staff.id && staff.nonExempt) {
            newStaff["hours"] = hours;
          }
        });
        allHoursApprovals.forEach((approvals) => {
          if (approvals.id === staff.id && staff.nonExempt) {
            newStaff["approvedHours"] = approvals;
          }
        });
        tempArray.push(newStaff);
      }
    });
    setStaffWithHours(tempArray);
  }, [allHours, allStaff, allHoursApprovals]);

  useEffect(() => {
    if (!payPeriods || !staffWithHours) return;
    const header = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Total", key: "total" },
    ];
    const approvalKey = `${payPeriods[currentPayPeriod].year}-week${payPeriods[currentPayPeriod].weekOneNumber}`;
    payPeriods[currentPayPeriod].datesArray.forEach((periodDate) => {
      header.push({ label: periodDate, key: periodDate });
    });
    const tempRows = [];
    staffWithHours.forEach((staff) => {
      const exportRow = { firstName: staff.firstName, lastName: staff.lastName, total: 0 };
      payPeriods[currentPayPeriod].datesArray.forEach((periodDate) => {
        if (staff.approvedHours?.[approvalKey]) {
          exportRow[periodDate] = staff.hours[periodDate] || 0;
          exportRow.total += staff.hours[periodDate] || 0;
        } else {
          exportRow[periodDate] = 0;
        }
      });
      tempRows.push(exportRow);
    });
    setHoursCSV(tempRows);
    setHoursCSVHeader(header);
  }, [currentPayPeriod, payPeriods, staffWithHours]);

  console.log(hoursCSV);

  return (
    <>
      <Typography sx={{ pt: 2, mb: 1 }} variant="h3">
        Review Hour Submissions
      </Typography>
      {payPeriods && hoursCSV && (
        <>
          <PayPeriodsNavigation
            payPeriod={payPeriods[currentPayPeriod]}
            setCurrentPayPeriod={setCurrentPayPeriod}
            hoursCSVHeader={hoursCSVHeader}
            hoursCSV={hoursCSV}
          />
          {staffWithHours &&
            staffWithHours.map((staff) => (
              <StaffHoursRow
                key={staff.id}
                staff={staff}
                payPeriod={payPeriods[currentPayPeriod]}
                currentPayPeriod={currentPayPeriod}
              />
            ))}
        </>
      )}
    </>
  );
}

import { DateTime } from "luxon";

export const settings = {
  acStartMonth: 8,
  acStartYear: 2021,
  acPeriod: 10,
  totalSickHours: 48,
  totalAdvHours: 48,
  totalBerHours: 24,
};

export const monthsObj = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const monthsArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const sickObj = {
  type: "Sick Leave",
  id: "Sick",
  possibleHours: 0,
  accruedHours: 0,
  usedHours: 0,
  futureApprovedHours: 0,
  remainingHours: 0,
  borrowedHours: 0,
  negativeHours: 0,
};
export const advObj = {
  type: "Advanced Notice",
  id: "Advanced",
  possibleHours: 0,
  accruedHours: 0,
  usedHours: 0,
  futureApprovedHours: 0,
  remainingHours: 0,
  borrowedHours: 0,
  negativeHours: 0,
};
export const berObj = {
  type: "Bereavement",
  id: "Bereavement",
  possibleHours: settings.totalBerHours,
  accruedHours: settings.totalBerHours,
  usedHours: 0,
  futureApprovedHours: 0,
  remainingHours: 0,
  borrowedHours: 0,
  negativeHours: 0,
};

export const blankForm = {
  PTOType: "",
  reason: "",
  fullDay: true,
  dateRange: [DateTime.now().toFormat("D"), DateTime.now().toFormat("D")],
  hours: 8,
  status: "pending",
  adminNotes: "",
  approver: "",
  requester: "",
  schoolYear: "",
  history: [],
};

import React from "react";
import Container from "@material-ui/core/Container";
import NavBar from "../../Shared/Navigation/Navbar";
import RequestsContainer from "../components/RequestsContainer";

export default function Requests() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: "64px" }}>
        <RequestsContainer />
      </Container>
    </>
  );
}

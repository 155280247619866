import React, { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { rolloverResetAtom } from "../../recoil/Atoms";
import { TextField, Box, Typography, Button, Grid } from "@mui/material";
import useSetDoc from "../../hooks/useSetDoc";

export default function Rollover({ PTOType, selectedStaff, rolloverObj }) {
  const { sendRequest: setDoc } = useSetDoc();
  const [rolloverAmount, setRolloverAmount] = useState(0);
  const [existingRollover, setExistingRollover] = useState();
  const rolloverReset = useSetRecoilState(rolloverResetAtom);

  useEffect(() => {
    if (!rolloverObj) return;
    if (rolloverObj[selectedStaff.id]) {
      setExistingRollover(rolloverObj[selectedStaff.id]);
    }
  }, [rolloverObj, selectedStaff]);

  const handleSave = async () => {
    const data = existingRollover
      ? { ...existingRollover, [PTOType.id]: rolloverAmount }
      : { [PTOType.id]: rolloverAmount };
    await setDoc({ col: "rollover", data: data, id: selectedStaff.id });
    rolloverReset((pV) => !pV);
    setRolloverAmount(0);
  };

  const handleEdit = (e) => {
    setRolloverAmount(+e.target.value);
  };

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item sm={8}>
        <TextField
          onChange={handleEdit}
          fullWidth
          value={String(rolloverAmount)}
          type="number"
          min="0"
          step="1"
        />
      </Grid>
      <Grid item sm={4}>
        <Button onClick={handleSave} variant="contained" fullWidth sx={{ height: 1 }}>
          Set RollOver
        </Button>
      </Grid>
    </Grid>
  );
}

import React from "react";
import Container from "@material-ui/core/Container";
import NavBar from "../../Shared/Navigation/Navbar";
import SummaryTableContainer from "../components/SummaryTableContainer";

export default function SummaryTablePage() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="xl" sx={{ minHeight: "100vh", mt: "64px" }}>
        <SummaryTableContainer />
      </Container>
    </>
  );
}

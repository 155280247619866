import { GridToolbar, DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

export default function SummaryTable({ rows, columns }) {
  return (
    <Box sx={{ mt: 2 }}>
      {rows && (
        <Box sx={{ height: "80vh" }}>
          <DataGridPro
            rows={rows}
            columns={columns}
            hover={false}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </Box>
      )}
    </Box>
  );
}

import { useState, useCallback } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const useDeactivateDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async ({ col, id }) => {
    setIsLoading(true);
    setError(null);
    try {
      const docRef = doc(db, col, id);
      const results = await updateDoc(docRef, {active: false});

      if (!results.id) {
        throw new Error("Request failed!");
      } else {
        return results.id;
      }
    } catch (err) {
      setError(err.message || "Something went wrong!");
    }
    setIsLoading(false);
  }, []);
  return { isLoading, error, sendRequest };
};

export default useDeactivateDoc;

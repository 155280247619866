import React from "react";
import { Button, Box, TextField, Grid, FormGroup, FormControlLabel, Switch } from "@mui/material";
import { importantDatesAtom, importantDatesResetAtom } from "../../recoil/Atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker from "@mui/lab/DateRangePicker";
import useAddDoc from "../../hooks/useAddDoc";
import ImportantDate from "./ImportantDate";
import { blankDateForm } from "../libraries/objects";

export default function ImportantDates({ dateForm, setDateForm, schoolYear }) {
  const importantDates = useRecoilValue(importantDatesAtom);
  const setImportantDatesReset = useSetRecoilState(importantDatesResetAtom);

  const { sendRequest: addDoc } = useAddDoc();
  const handleTextEdit = (e) => {
    setDateForm((pV) => ({ ...pV, name: e.target.value }));
  };

  const handleDateEdit = (range) => {
    const start = range[0] ? range[0].toLocaleString("en-US") : null;
    const end = range[1] ? range[1].toLocaleString("en-US") : null;
    setDateForm((pV) => {
      return { ...pV, date: [start, end], startDate: Number(range[0].toMillis()) };
    });
  };

  const handleSwitch = (e) => {
    setDateForm((pV) => {
      return { ...pV, [e.target.name]: e.target.checked };
    });
  };

  const handleSave = () => {
    addDoc({ col: "importantDates", data: { ...dateForm, schoolYear: schoolYear } });
    setImportantDatesReset((pV) => !pV);
    setDateForm(blankDateForm);
  };
  return (
    <>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item sm={3}>
          <TextField
            name="name"
            value={dateForm.name}
            fullWidth
            onChange={handleTextEdit}
          ></TextField>
        </Grid>
        <Grid item sm={6}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateRangePicker
              startText="Start of Range"
              endText="End of Range"
              value={dateForm.date}
              onChange={handleDateEdit}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} required />
                  <Box sx={{ mx: 2 }}>-</Box>
                  <TextField {...endProps} required />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch name="blackout" checked={dateForm.blackout} onChange={handleSwitch} />
              }
              label="Blackout"
            />
            <FormControlLabel
              control={<Switch name="holiday" checked={dateForm.holiday} onChange={handleSwitch} />}
              label="Holiday"
            />
          </FormGroup>
        </Grid>
        <Grid item sm={1}>
          <Button
            sx={{ flexGrow: 1, height: 1 }}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            Add Date
          </Button>
        </Grid>
      </Grid>
      {importantDates && importantDates.map((date) => <ImportantDate date={date} key={date.id} />)}
    </>
  );
}

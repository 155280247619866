import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  selectAllRequestsAtom,
  statusesAtom,
  staffObjAtom,
  selectImportantDatesAtom,
} from "../../recoil/Atoms";
import { Box } from "@mui/material";
import tippy from "tippy.js";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { DateTime } from "luxon";

export default function CalendarContainer() {
  const [calEvents, setCalEvents] = useState();
  const allRequests = useRecoilValue(selectAllRequestsAtom);
  const statuses = useRecoilValue(statusesAtom);
  const staffObj = useRecoilValue(staffObjAtom);
  const importantDates = useRecoilValue(selectImportantDatesAtom);

  useEffect(() => {
    if (!importantDates || !staffObj) return;
    const tempArray = [...importantDates];
    if (!allRequests) return;
    allRequests.forEach((r) => {
      if (!staffObj[r.requester] || staffObj[r.requester].terminated) {
        return;
      }
      if ((r.status === statuses.approved || r.status === statuses.unpaid) && r.dateRange[0] && r.dateRange[1]) {
        const startDT = DateTime.fromFormat(r.dateRange[0], "D");
        const endDT = DateTime.fromFormat(r.dateRange[1], "D").plus({ days: 1 });
        const start = startDT.toFormat("yyyy-MM-dd");
        const end = endDT.toFormat("yyyy-MM-dd");
        let title = "";
        let backgroundColor = "";
        let borderColor = "";
        if (r.fullDay) {
          title = `FD - ${r.requesterName} - ${r.PTOTypeName}`;
          backgroundColor = "#23018F";
          borderColor = "#23018F";
        } else {
          title = `PD - ${r.requesterName} - ${r.PTOTypeName} - Hours: ${r.hours} - Time Notes: ${r.additionalInfo}`;

          backgroundColor = "#B930E6";
          borderColor = "#B930E6";
        }

        tempArray.push({
          title: title,
          start: start,
          end: end,
          allday: true,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
        });
      }
    });
    setCalEvents(tempArray);
  }, [allRequests, statuses, importantDates]);

  const triggerTip = (info) => {
    tippy(info.el, {
      content: info.event.title,
      delay: [500, 10],
      duration: 10,
    });
  };

  return (
    <Box sx={{ pt: 5 }}>
      {calEvents && (
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={calEvents}
          weekends={false}
          eventDidMount={triggerTip}
        />
      )}
    </Box>
  );
}

import React, { useState } from "react";
import {
  Button,
  Dialog,
  Box,
  Typography,
  TextField,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import ImportantDates from "./ImportantDates";
import { blankDateForm } from "../libraries/objects";

export default function SchoolYearDialog({
  dialogOpen,
  setDialogOpen,
  schoolYearForm,
  setSchoolYearForm,
  saveForm,
}) {
  const [dateForm, setDateForm] = useState(blankDateForm);
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDateEdit = (value, id) => {
    setSchoolYearForm((pV) => {
      return { ...pV, [id]: value.toLocaleString("en-US") };
    });
  };

  const handleEdit = (e) => {
    setSchoolYearForm((pV) => {
      return { ...pV, [e.target.id]: e.target.value };
    });
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth={"lg"} fullWidth>
      <Typography variant="h2" sx={{ padding: 2, textAlign: "center" }}>
        Calendar Year Dialog
      </Typography>
      <DialogContent>
        <Typography variant="h5">School Year Name</Typography>
        <TextField
          required
          fullWidth
          id="name"
          type="text"
          variant="outlined"
          onChange={handleEdit}
          value={schoolYearForm.name}
        />
        <Typography sx={{ mt: 1 }} variant="h5">
          Start and End Dates
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                value={schoolYearForm.tenMonthStartDate}
                label="10 Month Employee Start Date"
                onChange={(e) => handleDateEdit(e, "tenMonthStartDate")}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                sx={{ flexGrow: 1 }}
                label="10 Month Employee End Date"
                value={schoolYearForm.tenMonthEndDate}
                onChange={(e) => handleDateEdit(e, "tenMonthEndDate")}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="12 Month Employee Start Date"
                value={schoolYearForm.twelveMonthStartDate}
                onChange={(e) => handleDateEdit(e, "twelveMonthStartDate")}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="12 Month Employee End Date"
                value={schoolYearForm.twelveMonthEndDate}
                onChange={(e) => handleDateEdit(e, "twelveMonthEndDate")}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={12}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="First Day of First Pay Cycle"
                value={schoolYearForm.firstPayWeek}
                onChange={(e) => handleDateEdit(e, "firstPayWeek")}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Typography sx={{ mt: 1 }} variant="h5">
          Holidays and Blackout Dates
        </Typography>
        <Box sx={{ mt: 2 }}>
          <ImportantDates
            dateForm={dateForm}
            setDateForm={setDateForm}
            schoolYear={schoolYearForm.id}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveForm}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

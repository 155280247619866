import React from "react";
import Container from "@material-ui/core/Container";
import NavBar from "../../Shared/Navigation/Navbar";
import HoursContainer from "../components/HoursContainer";

export default function Hours() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: "64px" }}>
        <HoursContainer />
      </Container>
    </>
  );
}

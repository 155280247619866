import { useState, useCallback } from "react";
import { DateTime } from "luxon";
import { useRecoilValue } from "recoil";
import { schoolYearsObjAtom, selectedSchoolYearAtom, statusesAtom } from "../recoil/Atoms";
import {
  calculateStartMonth,
  getAccrualStart,
  calculateSchoolMonth,
  calculateAmountPerMonth,
  round,
  calculateEndMonth,
} from "./libraries/functions";

const useGetSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const schoolYearsObj = useRecoilValue(schoolYearsObjAtom);
  const statuses = useRecoilValue(statusesAtom);
  const selectedSchoolYearId = useRecoilValue(selectedSchoolYearAtom);

  const calculatePTOSummary = ({
    user: { startDate: userStartDate, ptoPolicy, id },
    allRequests,
    PTOType,
    rolloverAmount,
    request,
  }) => {
    if (!selectedSchoolYearId || !schoolYearsObj || !schoolYearsObj[selectedSchoolYearId]) return;
    const selectedSchoolYear = schoolYearsObj[selectedSchoolYearId];

    const summaryObj = {
      accruedHours: 0,
      usedHours: 0,
      scheduledHours: 0,
      availableHours: 0,
      totalToAccrue: 0,
      totalApproved: 0,
      totalRemaining: 0,
      accruedOnRequestDate: 0,
      availableOnRequestDate: 0,
      totalHoursMissed: 0,
    };
    try {
      const dtToday = DateTime.now();
      const dtPolicyStartDate = DateTime.fromFormat(selectedSchoolYear.twelveMonthStartDate, "D");
      const nextYearPolicy = dtToday.toMillis() - dtPolicyStartDate.toMillis() > 0 ? 1 : 0;
      const requestDate = request ? DateTime.fromFormat(request.dateRange[0], "D") : dtToday;
      const dtUserStartDate = DateTime.fromFormat(userStartDate, "D");
      const schoolYear = schoolYearsObj[PTOType.schoolYear];
      const startYear = DateTime.fromFormat(schoolYear.twelveMonthStartDate, "D").year;
      const accrualEndMonth = calculateEndMonth(ptoPolicy, schoolYear);

      //******************************** Get Available hours ********************************//

      let accrualStartMonth;
      if (startYear - dtUserStartDate.year > -1) {
        accrualStartMonth = getAccrualStart(ptoPolicy, schoolYear);
      } else {
        accrualStartMonth = calculateStartMonth(dtUserStartDate);
      }

      const currentMonth = calculateSchoolMonth(dtToday);
      const requestMonth = calculateSchoolMonth(requestDate);
      const amountPerMonth = calculateAmountPerMonth(PTOType);
      const accruedSoFar = currentMonth - accrualStartMonth + 1;
      const accruedByRequest = requestMonth - accrualStartMonth + 1;

      if (PTOType.accrual) {
        const monthsToAccrue = accrualEndMonth - accrualStartMonth + 1;
        summaryObj.totalToAccrue = round(monthsToAccrue * amountPerMonth);
      } else {
        summaryObj.totalToAccrue = PTOType.totalHours;
      }

      summaryObj.accruedHours = PTOType.accrual
        ? round(amountPerMonth * accruedSoFar) * nextYearPolicy
        : PTOType.totalHours * nextYearPolicy;

      summaryObj.accruedOnRequestDate = PTOType.accrual
        ? round(amountPerMonth * accruedByRequest)
        : PTOType.totalHours;

      //******************************** Get Used and Future Hours ********************************//

      const userRequests = allRequests.filter(
        (request) =>
          request.requester === id &&
          request.PTOType === PTOType.id &&
          request.status === statuses.approved
      );

      const totalRequestsTaken = allRequests.filter(
        (request) =>
          request.requester === id &&
          request.PTOType === PTOType.id &&
          (request.status === statuses.approved || request.status === statuses.unpaid)
      );

      let usedHours = 0;
      let futureHours = 0;
      let byRequestDateHours = 0;
      let totalHoursMissed = 0;

      userRequests.forEach((r) => {
        const startDate = DateTime.fromFormat(r.dateRange[0], "D");
        if (startDate - dtToday < 0) {
          usedHours += r.hours;
        } else {
          futureHours += r.hours;
        }
        if (startDate - requestDate < 0) {
          byRequestDateHours += r.hours;
        }
      });

      totalRequestsTaken.forEach((r) => {
        const startDate = DateTime.fromFormat(r.dateRange[0], "D");
        if (startDate - dtToday < 0) {
          totalHoursMissed += r.hours;
        }
      });

      summaryObj.usedHours = usedHours;
      summaryObj.totalHoursMissed = totalHoursMissed;
      summaryObj.scheduledHours = futureHours;
      summaryObj.availableHours = round(
        summaryObj.accruedHours - summaryObj.usedHours + rolloverAmount
      );
      summaryObj.totalApproved = usedHours + futureHours;
      summaryObj.totalRemaining =
        summaryObj.totalToAccrue - summaryObj.totalApproved + rolloverAmount;
      summaryObj.availableOnRequestDate = round(
        summaryObj.accruedOnRequestDate - byRequestDateHours + rolloverAmount
      );

      return summaryObj;
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const getSummary = useCallback(calculatePTOSummary, [
    schoolYearsObj,
    selectedSchoolYearId,
    statuses.approved,
    statuses.unpaid,
  ]);
  return { isLoading, getSummary };
};

export default useGetSummary;

import React, { useState, useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  PTOTypesAtom,
  allStaffAtom,
  currentUserAtom,
  selectAllRequestsAtom,
} from "../../recoil/Atoms";
import { Typography } from "@mui/material";
import SummaryTable from "./SummaryTable";

export default function SummaryTableContainer() {
  const [rows, setRows] = useState();
  const currentUser = useRecoilValue(currentUserAtom);
  const allStaff = useRecoilValue(allStaffAtom);
  const allRequests = useRecoilValue(selectAllRequestsAtom);
  const ptoTypes = useRecoilValue(PTOTypesAtom);

  const summaryColumns = useMemo(() => {
    return [
      {
        field: "firstName",
        headerName: "First Name",
        resizeable: true,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
      {
        field: "lastName",
        headerName: "Last Name",
        resizeable: true,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
      {
        field: "totalHoursMissed",
        resizeable: true,
        headerName: "Total Hours Missed",
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
    ];
  }, []);

  useEffect(() => {
    if (!ptoTypes) return;
    ptoTypes.forEach((ptoType) => {
      summaryColumns.push({
        field: ptoType.id,
        headerName: ptoType.name,
        resizeable: true,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      });
    });
  }, [ptoTypes, summaryColumns]);

  useEffect(() => {
    if (!allStaff || !allRequests) return;
    const tempRows = [];
    allStaff.forEach((staffMember) => {
      if (!staffMember.active) return;
      const filteredRequests = allRequests.filter(
        (request) => request.requester === staffMember.email
      );

      let totals = {
        totalHoursMissed: 0,
      };
      ptoTypes.forEach((ptoType) => {
        if (!ptoType.active || !filteredRequests) return;
        totals[ptoType.id] = 0;
        const filteredByTypeRequests = filteredRequests.filter(
          (request) =>
            request.PTOType === ptoType.id &&
            request.status !== "cancelled" &&
            request.status !== "pending"
        );
        filteredByTypeRequests.forEach((request) => {
          const startDate = new Date(request.startDate);
          if (startDate > Date.now()) return;
          totals.totalHoursMissed += request.hours;
          totals[ptoType.id] += request.hours;
        });
      });
      tempRows.push({
        firstName: staffMember.firstName,
        lastName: staffMember.lastName,
        id: staffMember.id,
        ...totals,
      });
    });
    setRows(tempRows);
  }, [allStaff, allRequests, ptoTypes]);

  return (
    <>
      <Typography sx={{ pt: 2, marginBottom: "10px" }} variant="h3">
        PTO Request Summary Table
      </Typography>
      <SummaryTable columns={summaryColumns} rows={rows} />
    </>
  );
}
